import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { TableContext } from '../../context/TableProvider';

const Paginate = (props) => {

  const {tableState, paginate} = React.useContext(TableContext);
  const [paginateState, setPaginateState] = React.useState(null);

  const PageItems = () => {

  	let items = [];  

    if(paginateState.numPages > 5) {
      items.push(
        <React.Fragment key={props.table + '_prev'}>
          {
            paginateState.active !== 1 ?
            <Pagination.Prev key={props.table + '_0'} onClick={() =>
              paginate(props.table, paginateState.active - 1)
            }/> :
            <React.Fragment></React.Fragment>
          }
          {
            paginateState.active > 5 ?
            <Pagination.Ellipsis key={props.table + 'ellipsis_1'} disabled/> :
            <React.Fragment></React.Fragment>
          }
        </React.Fragment>
      );
    }

    let counter = paginateState.number;

  	for (counter; counter <= paginateState.pages; counter++) {
  	  items.push(
  	    <Pagination.Item key={props.table + '_' + counter} active={counter === paginateState.active}  onClick={(e) =>
            paginate(props.table, parseInt(e.target.textContent))
        }>
  	      {counter}
  	    </Pagination.Item>,
  	  );
  	}

    if(paginateState.numPages > 5) {
      items.push(
        <React.Fragment key={props.table + '_forward'}>
          {
            paginateState.active < paginateState.numPages - 5 ?
            <Pagination.Ellipsis key={props.table + 'ellipsis_2'} disabled/> :
            <React.Fragment></React.Fragment>
          }
          {
            paginateState.active !== paginateState.numPages ?
            <Pagination.Next key={props.table + '_1000'} onClick={() =>
              paginate(props.table, paginateState.active + 1)
            }/> :
            <React.Fragment></React.Fragment>
          }
        </React.Fragment>
      );
    }

    return (items);
  }

  React.useEffect(() => {

    let active = tableState[props.table].pageIndex;
    let numPages = Math.ceil(tableState[props.table].total / tableState[props.table].pageSize);
    let number = (active%5 === 0) ? (Math.floor(active / 5) * 5) - 4 : (Math.floor(active / 5) * 5) + 1;
    let pages = (numPages > 5) ? number + 4 : numPages;

    if(tableState[props.table]) {
      setPaginateState({
        active: active,
        numPages: numPages,
        pages: pages,
        number: number 
      });
    }

  }, [tableState[props.table]])

  if(paginateState && tableState && tableState[props.table] && !tableState[props.table].hidePagination) {
    return (
      <React.Fragment>
        <Pagination className="mt-5">
        	<PageItems />
        </Pagination>
      </React.Fragment>
    )
  } else {
    return(
      <React.Fragment></React.Fragment>
    );
  }
};

export default Paginate;