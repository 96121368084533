import React, { PureComponent } from 'react';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { AuthContext } from '../../context/AuthProvider';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

class Chart extends PureComponent {

  /*getMaxDomainValue(data) {

    let total = 0;

    data.map((column, i) => {
      if(column.total > total) {
        total = column.total;
      }
    });

    console.log(total);

    return total;
  }*/

	render() {
		return (
      <ResponsiveContainer width='100%' aspect={4.0/3.0}>
  		  <BarChart
  		    data={this.props.data}
          layout="vertical"
  		  >
  		    <XAxis type="number" />
  		    <YAxis type="category" dataKey="name" />
  		    <Tooltip />
          <Bar dataKey="enabled" stackId="a" fill="#82ca9d" />
  		    <Bar dataKey="total" stackId="a" fill="#8884d8" />
  		  </BarChart>
      </ResponsiveContainer>
		);
	}
}


const Home = () => {

  const [homeData, setHomeData] = React.useState(null);
  const {state} = React.useContext(AuthContext);

  const getHomeData = async (cancelToken) => {
    try {
        const opts = {
          headers: {
              'X-Elaisian-Authorization': state.user.token
          },
          cancelToken: cancelToken
        };
        const response = await API.get('home', opts);
        if (response.data.status === 'ok') {
          setHomeData(response.data.result);
        } else {
          console.log("ERROR: ", response.error_code);
        }
    } catch (e) {
        console.log(e);
    }
  }

	React.useEffect(() => {
		const source = CancelToken.source();

    getHomeData(source.token);

    return () => {
        source.cancel();
    };
   }, []);

	return(
    homeData ?
		<div className="container-fluid">
			<div className="row mb-5">
				<div className="col-6">
					<div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col text-left">
                  <h3>Devices</h3>
                </div>
                <div className="col text-right">
                  <h3>{ homeData.devices.enabled}/{homeData.devices.total }</h3>
                </div>
              </div>
							<Chart data={homeData.devices.device_type} />
						</div>
					</div>
				</div>
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col text-left">
                  <h3>Terrains</h3>
                </div>
                <div className="col text-right">
                  <h3>{ homeData.terrains.enabled}/{homeData.terrains.total }</h3>
                </div>
              </div>
              <Chart data={homeData.terrains.crop_type} enabled={homeData.terrains.enabled} total={homeData.terrains.total} />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-around">
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col text-left">
                  <h3>Users</h3>
                </div>
                <div className="col text-right">
                  <h3>{ homeData.users.enabled}/{homeData.users.total }</h3>
                </div>
              </div>
              <Chart data={homeData.users.country} enabled={homeData.users.enabled} total={homeData.users.total} />
            </div>
          </div>
        </div>
        <div className="col-6">
        </div>
			</div>
		</div>
    : <div className="container-fluid">
        <div className="row">
          <div className="col text-center">
            <b>LOADING...</b>
          </div>
        </div>
      </div>
	)
};

export default Home;