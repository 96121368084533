import React from 'react';
import { TableProvider } from './TableProvider';
import { ModalProvider } from './ModalProvider';

export const GlobalProvider = ({ children }) => {
	return(
		<ModalProvider>
		  	<TableProvider>
			   {children}
			</TableProvider>
		</ModalProvider>
  );
}