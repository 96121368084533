import React from 'react';
import Header from '../header/Header';
import SideBar from '../sidebar/SideBar';
import { ConfigContext } from '../../context/ConfigProvider';
import { LayoutContext } from '../../context/LayoutProvider';

const Layout = ({ children }) => {

  const {layoutState} = React.useContext(LayoutContext);
  const {loading} = React.useContext(ConfigContext);

  return (
    <div className={'d-flex ' + layoutState.wrapperClass} id="wrapper">
      <SideBar />
      <div id="page-content-wrapper">
      	<Header />
        { loading ?
          <div className="text-center">
            <div className="spinner-border mt-5" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
          children
        }
      </div>
    </div>
  )
}

export default Layout;
