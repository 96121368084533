import React from 'react';
import { AuthContext } from '../../context/AuthProvider';
import DatePicker from 'react-datepicker';
import Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router-dom';
import Table from '../../components/table/Table';
import 'react-datepicker/dist/react-datepicker.css';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';

const ManageDevice = (props) => {

    const manageDeviceInitialState = {
        terrainDeviceLoaded: false,
        terrainId: props.terrainId,
        userId: props.userId,
        devicesList: null,
        deviceId: '',
        coordLat: '',
        coordLng: '',
        dateInstall: null, 
        dateStart: null,
        dateEnd: null
    };

    const errorAlertInitalState = {
        show: false,
        message: ''
    };
    
    const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
    const [manageDeviceState, setManageDeviceState] = React.useState(manageDeviceInitialState);
    const {tableDispatch} = React.useContext(TableContext);
    const [loading, setLoading] = React.useState(false);
    const {state} = React.useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        try {
            const token = state.user.token;
            
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': token
                }
            };

            const data = {
                device_id: manageDeviceState.deviceId,
                coord_lat: manageDeviceState.coordLat,
                coord_lng: manageDeviceState.coordLng,
                date_start: manageDeviceState.dateStart,
                date_end: manageDeviceState.dateEnd,
                date_install: manageDeviceState.dateInstall
            };

            let response;

            if(props.edit) {
                response = await API.put('operations/terrains/' + manageDeviceState.terrainId + '/devices', data, opts);
            } else {
                response = await API.post('operations/terrains/' + manageDeviceState.terrainId + '/devices', data, opts);
            }
            
            if (response.data.status === 'ok') {
                setManageDeviceState(manageDeviceInitialState);
                props.setEditTerrainDevice({
                    edit: false,
                    deviceId: null
                });
                tableDispatch({
                  type: 'DATA',
                  payload: {  
                    table: 'terrainDevices-' + manageDeviceState.terrainId,
                    data: []
                  }
                });
                setLoading(false);
            } else {
                setErrorAlertState({
                    show: true,
                    message: response.error_message
                });
                setLoading(false);
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            setErrorAlertState({
                show: true,
                message: e.response.data.error_message
            });
            setLoading(false);
            console.log(e);
        }
    }

    const getUserDevices = async (cancelToken) => {

        setManageDeviceState({ 
            ...manageDeviceState,
            isLoading: true 
        });

        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                cancelToken: cancelToken,
                params: {
                    'search_type': 'ac_np'
                }
            };
            const response = await API.get('operations/users/' + manageDeviceState.userId + '/devices', opts);
            if (response.data.status === 'ok') {
                setManageDeviceState({ 
                    ...manageDeviceState,
                    devicesList: response.data.result,
                    deviceId: (response.data.result[0]) ? response.data.result[0].id : ''
                });
            } else {
                console.log("ERROR: ", response.data.result);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getTerrainDevice = async (cancelToken) => {
        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                cancelToken: cancelToken
            };
            const response = await API.get('operations/terrains/' + manageDeviceState.terrainId + '/devices/' + props.deviceId, opts);
            if (response.data.status === 'ok') {
                setManageDeviceState({ 
                    ...manageDeviceState,
                    terrainDeviceLoaded: true,
                    devicesList: [
                        {
                            id: props.deviceId,
                            device_code: response.data.result.device_code
                        }
                    ],
                    deviceId: props.deviceId,
                    coordLat: response.data.result.info.coord_lat,
                    coordLng: response.data.result.info.coord_lng,
                    dateInstall: response.data.result.info.date_install ? new Date(response.data.result.info.date_install) : null, 
                    dateStart: response.data.result.info.date_start ? new Date(response.data.result.info.date_start) : null ,
                    dateEnd: response.data.result.info.date_end ? new Date(response.data.result.info.date_end) : null
                });
            } else {
                console.log("ERROR: ", response.data.result);
            }
        } catch (e) {
            console.log(e);
        }
    }

    React.useEffect(() => {

        const source = CancelToken.source();

        if(!props.edit && !manageDeviceState.devicesList) {
            getUserDevices(source.token);
        }

        if(props.edit && (!manageDeviceState.terrainDeviceLoaded || (props.deviceId !== manageDeviceState.deviceId))) {
            getTerrainDevice(source.token);
        }

        return () => {
            source.cancel();
        };

    }, [props.edit, props.deviceId, manageDeviceState.devicesList])

    return(
        !manageDeviceState.devicesList || !manageDeviceState.devicesList.length 
        ? <React.Fragment></React.Fragment>
        : <div className="card">
           <div className="card-body">
            <h5 className="card-title">{props.edit ? 'Edit' : 'Install'}</h5>
            <form className="m-1 mb-0" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group col-auto">
                        <select className="custom-select" onChange={(e) => setManageDeviceState({
                            ...manageDeviceState,
                            deviceId: e.currentTarget.value
                        })} required>
                          {manageDeviceState.devicesList.map((device, i) => (
                            <option key={i} value={device.id}>{device.device_code}</option>
                          ))}
                        </select>
                    </div>
                    <div className="form-group col-auto">
                        <input type="text" className="form-control" placeholder="Latitude" value={manageDeviceState.coordLat} onChange={(e) => setManageDeviceState({ 
                            ...manageDeviceState,
                            coordLat: e.currentTarget.value
                        }) } />
                    </div>
                    <div className="form-group col-auto">
                        <input type="text" className="form-control" placeholder="Longitude" value={manageDeviceState.coordLng} onChange={(e) => setManageDeviceState({ 
                            ...manageDeviceState,
                            coordLng: e.currentTarget.value
                        }) } />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-auto">
                        <DatePicker
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeInput
                            selected={manageDeviceState.dateInstall}
                            onChange={(date) => setManageDeviceState({
                                ...manageDeviceState,
                                dateInstall: date
                            })}
                            locale="en"
                            className="form-control"
                            name="date_install" 
                            id="date_install" 
                            placeholderText="Install Date"
                            popperPlacement="right-start"
                            isClearable
                          />
                    </div>
                    <div className="form-group col-auto">
                        <DatePicker
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeInput
                            selected={manageDeviceState.dateStart}
                            onChange={(date) => setManageDeviceState({
                                ...manageDeviceState,
                                dateStart: date
                            })}
                            locale="en"
                            selectsStart
                            startDate={manageDeviceState.dateStart}
                            endDate={manageDeviceState.dateEnd}
                            className="form-control"
                            name="date_start" 
                            id="date_start" 
                            placeholderText="Start Date"
                            popperPlacement="right-start"
                            isClearable
                          />
                    </div>
                    <div className="form-group col-auto">     
                        <DatePicker
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeInput
                            selected={manageDeviceState.dateEnd}
                            onChange={(date) => setManageDeviceState({
                                ...manageDeviceState,
                                dateEnd: date
                            })}
                            locale="en"
                            selectsEnd
                            startDate={manageDeviceState.dateStart}
                            endDate={manageDeviceState.dateEnd}
                            minDate={manageDeviceState.dateStart}
                            className="form-control"
                            name="date_end" 
                            id="date_end" 
                            placeholderText="End Date"
                            popperPlacement="right-start"
                            isClearable
                          />
                    </div>
                </div>
                <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
                     ...errorAlertState,
                     show: false
                  })} dismissible>
                    <Alert.Heading>Ops!</Alert.Heading>
                    <p>
                      { errorAlertState.message }
                    </p>
                </Alert>
                <div className="form-row">
                    <div className="form-group col-auto">
                        <button type="submit" className="btn btn-primary mr-2" disabled={loading}>
                            <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                            {props.edit ? 'Edit' : 'Install'} Device
                        </button>
                        <button type="reset" className={props.edit ? 'btn btn-secondary' : 'sr-only'} onClick={() => {
                            setManageDeviceState(manageDeviceInitialState);
                            props.setEditTerrainDevice({
                                edit: false,
                                deviceId: null
                            });
                        }}>EXIT</button>
                    </div>
                </div>
            </form>
           </div>
        </div>
    );
};

const ImportCSV = (props) => {

    const importCsvInitialState = {
        dateStart: null,
        dateEnd: null,
        csvType: 'openweather'
    };

    const errorAlertInitalState = {
        show: false,
        message: ''
    };
    
    const [successAlert, setSuccessAlertState] = React.useState(false);
    const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
    const [importCsvState, setImportCsvState] = React.useState(importCsvInitialState);
    const [loading, setLoading] = React.useState(false);
    const {state} = React.useContext(AuthContext);
    const fileCSV = React.createRef();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        try {
            const token = state.user.token;
            
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': token
                }
            };

            const formData = new FormData();

            formData.append('installation_id', props.device.info.id);
            formData.append('date_start', importCsvState.dateStart.toISOString());
            formData.append('date_end', importCsvState.dateEnd.toISOString());
            formData.append('file_csv', fileCSV.current.files[0]);

            const response = await API.post('operations/import/transmissions/csv/' + importCsvState.csvType, formData, opts);
            
            if (response.data.status === 'ok') {
                setLoading(false);
                setSuccessAlertState(true);
                setTimeout(function() {
                    setImportCsvState(importCsvInitialState);
                    props.setImportCsvData({
                        show: false,
                        device: null
                    });
                }, 1500);
            } else {
                setErrorAlertState({
                    show: true,
                    message: response.error_message
                });
                setLoading(false);
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            setErrorAlertState({
                show: true,
                message: e.response.data.error_message
            });
            setLoading(false);
            console.log(e);
        }
    }

    return(
        !successAlert
        ? <div className="card">
           <div className="card-body">
            <h5 className="card-title">Import CSV ({props.device.device_code})</h5>
            <form className="m-1 mb-0" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group col-auto">
                        <select className="custom-select" onChange={(e) => setImportCsvState({
                            ...importCsvState,
                            csvType: e.currentTarget.value
                        })} required>
                            <option value="openweather">OPEN WEATHER</option>
                        </select>
                    </div>
                    <div className="form-group col-auto">
                        <input type="file" className="form-control" id="validatedCSVFile" required ref={fileCSV}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-auto">
                        <DatePicker
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeInput
                            selected={importCsvState.dateStart}
                            onChange={(date) => setImportCsvState({
                                ...importCsvState,
                                dateStart: date
                            })}
                            locale="en"
                            selectsStart
                            startDate={importCsvState.dateStart}
                            endDate={importCsvState.dateEnd}
                            className="form-control"
                            name="date_start" 
                            id="date_start" 
                            placeholderText="Start Date"
                            popperPlacement="right-start"
                            isClearable
                            required
                          />
                    </div>
                    <div className="form-group col-auto">     
                        <DatePicker
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeInput
                            selected={importCsvState.dateEnd}
                            onChange={(date) => setImportCsvState({
                                ...importCsvState,
                                dateEnd: date
                            })}
                            locale="en"
                            selectsEnd
                            startDate={importCsvState.dateStart}
                            endDate={importCsvState.dateEnd}
                            minDate={importCsvState.dateStart}
                            className="form-control"
                            name="date_end" 
                            id="date_end" 
                            placeholderText="End Date"
                            popperPlacement="right-start"
                            isClearable
                            required
                          />
                    </div>
                </div>
                <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
                     ...errorAlertState,
                     show: false
                  })} dismissible>
                    <Alert.Heading>Ops!</Alert.Heading>
                    <p>
                      { errorAlertState.message }
                    </p>
                </Alert>
                <div className="form-row">
                    <div className="form-group col-auto">
                        <button type="submit" className="btn btn-primary mr-2" disabled={loading}>
                            <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                            Import CSV
                        </button>
                        <button type="reset" className={'btn btn-secondary'} onClick={() => {
                            setImportCsvState(importCsvInitialState);
                            props.setImportCsvData({
                                show: false,
                                device: null
                            });
                        }}>EXIT</button>
                    </div>
                </div>
            </form>
           </div>
          </div>
        : <Alert variant="success">
            <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
          </Alert>
    );
};

const TerrainDevices = (props) => {

    const {tableState, tableDispatch} = React.useContext(TableContext);
    const {modalDispatch} = React.useContext(ModalContext);
    const [editTerrainDevice, setEditTerrainDevice] = React.useState({
        deviceId: null,
        edit: false
    });
    const [importCsvData, setImportCsvData] = React.useState({
        device: null,
        show: false
    });
    const {state, canManage} = React.useContext(AuthContext);
    const history = useHistory();

    const terrainDevicesColumns = [
        {
            id: 'id',
            name: 'ID DB',
            type: 'string'
        },{
            id: 'device_code',
            name: 'ID Device',
            type: 'string'
        },{
            id: 'name',
            name: 'Name',
            type: 'string'
        },{
            id: 'device_type',
            name: 'Type',
            type: 'string'
        },{
            id: 'status',
            name: 'Status',
            type: 'string'
        },{
            id: 'created',
            name: 'Installed',
            type: 'timestamp',
            more: 'info'
        },{
            id: 'date_start',
            name: 'Date Start',
            type: 'timestamp',
            more: 'info'
        }
    ];

    const actions = canManage('terrains','write') ? [
        {
            name: 'Uninstall',
            className: 'danger',
            call: async (device) => {
                var confirm = window.confirm("Are you sure?");
                if (confirm) {
                    try {
                        const opts = {
                            headers: {
                                'X-Elaisian-Authorization': state.user.token
                            }
                        };
                        const response = await API.delete('operations/terrains/' + props.element.id + '/devices/' + device.id, opts);
                        if (response.data.status === 'ok') {
                            tableDispatch({
                              type: 'DATA',
                              payload: {  
                                table: 'terrainDevices-' + props.element.id,
                                data: []
                              }
                            });
                        } else {
                            console.log("ERROR: ", response.error_code);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        },{
            name: 'Edit',
            call: (device) => {
                setImportCsvData({
                    device: null,
                    show: false
                });
                setEditTerrainDevice({
                    deviceId: device.id,
                    edit: true
                });
            }
        },{
            name: 'Import CSV',
            call: (device) => {
                setImportCsvData({
                    device: device,
                    show: true
                });
            }
        },{
            name: 'Details',
            call: (device) => {
                modalDispatch({
                    type: 'CLOSE'
                });
                history.push('/devices/' + device.id);
            }
        }
    ] : [];

    const getTerrainDevices = async (cancelToken) => {
        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                cancelToken: cancelToken
            };
            const response = await API.get('operations/terrains/' + props.element.id + '/devices', opts);
            if (response.data.status === 'ok') {
                if(response.data.result.length) {
                    tableDispatch({
                      type: 'DATA',
                      payload: {  
                        table: 'terrainDevices-' + props.element.id,
                        data: response.data.result
                      }
                    });
                } else {
                    tableDispatch({
                        type: 'EMPTY',
                        payload: {  
                          table: 'terrainDevices-' + props.element.id
                        }
                    });
                }
            } else {
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {

        const source = CancelToken.source();

        if(!tableState['terrainDevices-' + props.element.id]) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: 'terrainDevices-' + props.element.id,
                columns: terrainDevicesColumns,
                data: [],
                filters: [],
                orders: [],
                hideFilters: true,
                disableOrder: true
              }
            });
        }

        if(tableState && tableState['terrainDevices-' + props.element.id] && !tableState['terrainDevices-' + props.element.id].data.length && !tableState['terrainDevices-' + props.element.id].empty) {
            getTerrainDevices(source.token);
        }

        return () => {
            source.cancel();
        };

    }, [tableState['terrainDevices-' + props.element.id]])

    return (
        <React.Fragment>
            <div className="row">
                <div className="col">
                    {
                        !importCsvData.show
                        ? <ManageDevice edit={editTerrainDevice.edit} deviceId={editTerrainDevice.deviceId} terrainId={props.element.id} userId={props.element.user_id} setEditTerrainDevice={setEditTerrainDevice} />
                        : <ImportCSV device={importCsvData.device} setImportCsvData={setImportCsvData} />
                    }
                </div>
                <div className="mt-1 col-12">
                    <Table table={'terrainDevices-' + props.element.id} actions={actions} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default TerrainDevices;