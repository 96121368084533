import React from 'react';
import './Terrains.css';
import Col from 'react-bootstrap/Col';
import { AuthContext } from '../../context/AuthProvider';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ConfigContext } from '../../context/ConfigProvider';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

const TerrainType = (props) => {

  const {configState} = React.useContext(ConfigContext);

  const cropType = configState.crop_type;

  const CropVariants = () => {
    return(
      props.formParams.crop_type !== null
      ? <Form.Group as={Col}>
        <Form.Label htmlFor="new-crop_variant"><b>Crop Variant</b></Form.Label>
        <Form.Control id="new-crop_variant" as="select" custom value={props.formParams.crop_variant === null ? '' : props.formParams.crop_variant} onChange={(e) => {
          props.setFormParams({
            ...props.formParams,
            crop_variant: e.currentTarget.value
          });
        }} required>
          <option label=" "></option>
          {cropType[props.formParams.crop_type].crop_variant.map((variant, i) => (
            <option key={i} value={variant.id}>{variant.label}</option>
          ))}
       </Form.Control>
      </Form.Group>
      : <Form.Group as={Col}></Form.Group>
    );
  }

  const TerrainTypes = () => {
    return(
      props.formParams.crop_type !== null
      ? <Form.Group as={Col}>
        <Form.Label htmlFor="new-terrain_type"><b>Terrain Type</b></Form.Label>
        <Form.Control id="new-terrain_type" as="select" custom value={props.formParams.terrain_type === null ? '' : props.formParams.terrain_type} onChange={(e) => {
          props.setFormParams({
            ...props.formParams,
            terrain_type: e.currentTarget.value
          });
        }} required>
          <option label=" "></option>
          {cropType[props.formParams.crop_type].terrain_type.map((terrainType, i) => (
            <option key={i} value={terrainType.id}>{terrainType.label}</option>
          ))}
       </Form.Control>
      </Form.Group>
      : <Form.Group as={Col}></Form.Group>
    );
  }

  return(
    <React.Fragment>
      <Form.Group as={Col}>
        <Form.Label htmlFor="cropType"><b>Crop</b></Form.Label>
        <Form.Control id="cropType" as="select" value={props.formParams.crop_type || ''} custom onChange={(e) => {
          props.setFormParams({
            ...props.formParams,
            crop_type: e.currentTarget.value,
            crop_variant: null,
            terrain_type: null
          });
        }}>
          <option label=" "></option>
          {cropType.map((crop, i) => (
            <option key={i} value={crop.id}>{crop.label}</option>
          ))}
       </Form.Control>
      </Form.Group>
      <CropVariants />
      <TerrainTypes />
    </React.Fragment>
  );
}

const ManageTerrain = (props) => {

    const formParamsInitialState = {
        user_id: null,
        terrain_name: null,
        terrain_location : '',
        terrain_location_id: null,
        coord_lat: null,
        coord_lng: null,
        crop_type: null,
        crop_variant: null,
        terrain_type: null,
        bio_cultivation : false,
        enabled: false,
        features: [],
        ad_num_region: '',
        ad_num_province: '',
        ad_num_commune: ''
    };

    const addUserInitialState = {
        isLoading: false,
        options: [],
        selected: [{
          'id': '',
          'email': ''
        }]
    };

    const errorAlertInitalState = {
        show: false,
        message: ''
    };

    const {state} = React.useContext(AuthContext);
    const {configState} = React.useContext(ConfigContext);
    const [validated, setValidated] = React.useState(false);
    const [successAlert, setSuccessAlertState] = React.useState(false);
    const [formParams, setFormParams] = React.useState(formParamsInitialState);
    const [addUserState, setAddUserState] = React.useState(addUserInitialState);
    const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
    const {tableDispatch} = React.useContext(TableContext);
    const {modalDispatch} = React.useContext(ModalContext);
    const [loading, setLoading] = React.useState(false);

    const handleUserSearch = async (query) => {

        setAddUserState({ 
            ...addUserState,
            isLoading: true 
        });

        try {
            const opts = {
              headers: {
                'X-Elaisian-Authorization': state.user.token
              },
              params: {
                filter:  'email,starts_with,' + query,
                'search_type': 'ac'
              }
            };
            const response = await API.get('operations/users', opts);
            if (response.data.status === 'ok') {
              setAddUserState({ 
                ...addUserState,
                isLoading: false,
                options: response.data.result.users
              });
            } else {
              setAddUserState({ 
                ...addUserState,
                isLoading: false 
              });
              console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
          setAddUserState({ 
            ...addUserState,
            isLoading: false 
          });
          console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        const form = e.currentTarget;

        if (form.checkValidity()) {

            try {
                const token = state.user.token;
                const opts = {
                    headers: {
                        'X-Elaisian-Authorization': token
                    }
                };

                let response;

                if(props.action === 'edit') {
                    response = await API.put('operations/terrains/' + props.id, formParams, opts);
                } else {
                    response = await API.post('operations/terrains', formParams, opts);
                }
                if (response.data.status === 'ok') {
                    setSuccessAlertState(true);

                    setTimeout(function() {
                        modalDispatch({
                            type: 'CLOSE'
                        });
                        tableDispatch({
                            type: 'DATA',
                            payload: {
                              'table': 'terrainsDetails-' + props.id,
                              'data': []
                            }
                        });
                        tableDispatch({
                            type: 'DATA',
                            payload: {
                              'table': 'terrains',
                              'data': []
                            }
                        });
                    }, 1500)
                } else {
                    setErrorAlertState({
                        show: true,
                        message: response.error_message
                    });

                    setLoading(false);
                }
            } catch (e) {
                setErrorAlertState({
                    show: true,
                    message: e.response.data.error_message
                });
                setLoading(false);
            }
        } else {
            setValidated(true);
            setLoading(false);
        }
    }

    const handleGeoCodeSelect = (selected) => {
      geocodeByPlaceId(selected.place_id)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
          const x = formParams.coord_lng || lng;
          const y = formParams.coord_lat || lat;
          setFormParams({
              ...formParams,
              terrain_location: selected.structured_formatting.main_text,
              terrain_location_id: selected.place_id,
              coord_lat: y,
              coord_lng: x
          });
      });
    }

    const getTerrainData = async (cancelToken) => {
      try {
          const opts = {
            headers: {
                'X-Elaisian-Authorization': state.user.token
            },
            cancelToken: cancelToken
          };
          const response = await API.get('operations/terrains/' + props.id, opts);
          if (response.data.status === 'ok') {
              setFormParams(response.data.result);
              getUserData(response.data.result.user_id);
          } else {
            console.log("ERROR: ", response.error_code);
          }
      } catch (e) {
          console.log(e);
      }
    };

    const getUserData = async (userId, cancelToken) => {
      try {
          const opts = {
            headers: {
              'X-Elaisian-Authorization': state.user.token
            },
            cancelToken: cancelToken
          };
          const response = await API.get('operations/users/' + userId, opts);
          if (response.data.status === 'ok') {
            setAddUserState({ 
              ...addUserState,
              options: [response.data.result],
              selected: [response.data.result]
            });
          } else {
            console.log("ERROR: ", response.error_code);
          }
      } catch (e) {
          console.log(e);
      }
    };

    React.useEffect(() => {
      if(props.action === 'edit') {
        const source = CancelToken.source();
        getTerrainData(source.token);
        return () => {
          source.cancel();
        };
      }
    }, []);

    return (
        !successAlert
        ?  <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
              <Form.Row>
                <Form.Group as={Col} xs="6">
                  <Form.Label htmlFor="add-user"><b>User</b></Form.Label>
                  <AsyncTypeahead
                    {...addUserState}
                    id="add-user"
                    labelKey="email"
                    minLength={2}
                    onSearch={handleUserSearch}
                    onChange={(selected) => {
                      setAddUserState({ 
                        ...addUserState,
                        selected: selected
                      });
                      setFormParams({ 
                        ...formParams,
                        user_id: selected[0] ? selected[0].id : ''
                      });
                    }}
                    renderMenuItemChildren={(option, props) => (
                      <div key={option.id}>
                        { option.email }
                      </div>
                    )}
                    options={addUserState.options}
                    inputProps={{ required: true }}
                    selected={addUserState.selected}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="new-terrain_name">
                  <Form.Label><b>Terrain Name</b></Form.Label>
                  <Form.Control type="text" value={formParams.terrain_name || ''} onChange={(e) => {
                      setFormParams({
                        ...formParams,
                        terrain_name: e.currentTarget.value
                      });
                  }} required />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                  <Form.Group as={Col}>
                      <Form.Label htmlFor="new-terrain_location"><b>Terrain Location</b></Form.Label>
                      <GooglePlacesAutocomplete
                        id="new-terrain_location"
                        inputClassName="form-control"
                        onSelect={handleGeoCodeSelect}
                        withSessionToken={true}
                        initialValue={formParams.terrain_location}
                        placeholder=""
                        suggestionsClassNames={{ container: 'rbt-menu dropdown-menu show', suggestion: 'dropdown-item', suggestionActive: 'active' }}
                      />
                  </Form.Group>
                  <Form.Group as={Col} controlId="new-coord_lat">
                      <Form.Label><b>Latitude</b></Form.Label>
                      <Form.Control type="text" value={formParams.coord_lat || ''} onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              coord_lat: e.currentTarget.value
                          });
                      }} required />
                  </Form.Group>
                  <Form.Group as={Col} controlId="new-coord_lng">
                      <Form.Label><b>Longitude</b></Form.Label>
                      <Form.Control type="text" value={formParams.coord_lng || ''} onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              coord_lng: e.currentTarget.value
                          });
                      }} required />
                  </Form.Group>
              </Form.Row>
              <Form.Row>
                  <Form.Group as={Col} controlId="new-ad_num_region">
                      <Form.Label><b>Region AD num</b></Form.Label>
                      <Form.Control type="text" value={formParams.ad_num_region || ''} onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              ad_num_region: e.currentTarget.value
                          });
                      }} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="new-ad_num_province">
                      <Form.Label><b>Province AD num</b></Form.Label>
                      <Form.Control type="text" value={formParams.ad_num_province || ''} onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              ad_num_province: e.currentTarget.value
                          });
                      }} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="new-ad_num_commune">
                      <Form.Label><b>Commune AD num</b></Form.Label>
                      <Form.Control type="text" value={formParams.ad_num_commune || ''} onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              ad_num_commune: e.currentTarget.value
                          });
                      }} />
                  </Form.Group>
              </Form.Row>
              <Form.Row>
                <TerrainType setFormParams={setFormParams} formParams={formParams} />
              </Form.Row>
              <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Check
                      custom
                      type="switch"
                      label="BIO"
                      id="new-bio_cultivation"
                      checked={formParams.bio_cultivation}
                      onChange={(e) => {
                        setFormParams({
                          ...formParams,
                          bio_cultivation: e.currentTarget.checked
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Check
                      custom
                      type="switch"
                      label="Enabled"
                      id="new-enabled"
                      checked={formParams.enabled}
                      onChange={(e) => {
                        setFormParams({
                          ...formParams,
                          enabled: e.currentTarget.checked
                        });
                      }}
                    />
                  </Form.Group>
              </Form.Row>
              <Form.Row>
                  <Form.Group as={Col}>
                      <Form.Label><b>Data Features</b></Form.Label>
                      {
                          configState.features.transmission_data.map((type, i) => (
                              <Form.Check
                                  type="switch"
                                  label={type.label}
                                  id={type.id}
                                  key={type.name}
                                  disabled={type.id === 'data_basic'}
                                  checked={!!formParams.features[type.id]}
                                  onChange={(e) => {
                                      let oldValue = formParams.features[type.id];
                                      let newFeatures = {
                                          ...formParams.features,
                                          [type.id]: !oldValue
                                      }
                                      setFormParams({
                                          ...formParams,
                                          features: newFeatures
                                      });
                                  }}
                              />
                          ))
                      }
                  </Form.Group>
              </Form.Row>
              <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
                 ...errorAlertState,
                 show: false
              })} dismissible>
                <Alert.Heading>Ops!</Alert.Heading>
                <p>
                  { errorAlertState.message }
                </p>
              </Alert>
              <Form.Row className="mt-3">
                  <Col>
                      <Button variant="primary" type="submit" disabled={loading}>
                        <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                        INVIA
                      </Button>
                  </Col>
              </Form.Row>
          </Form>
        : <Alert variant="success">
            <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
          </Alert>
    );
}

export default ManageTerrain;