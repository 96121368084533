import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProtectedTest extends Component {

    render() {
        const { params } = this.props.match;
        return (
            <div>
                <h1>Protected Test Page</h1>
                <p>{params.id}</p>
            </div>
        )
    }
    
}

ProtectedTest.propTypes = {
    match: PropTypes.object
}

export default ProtectedTest;