import React from 'react';
import Col from 'react-bootstrap/Col';
import { AuthContext } from '../../context/AuthProvider';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ConfigContext } from '../../context/ConfigProvider';

const ManageNotification = (props) => {

  const {configState} = React.useContext(ConfigContext);

  const notificationTypes = configState.notification_type;

  const notificationLevels = configState.notification_level;

  const formParamsInitialState = {
    user_id: '',
    terrain_id: '',
    device_id: '',
    notification_type: '',
    notification_level: '',
    message: ''
  };

  const addUserInitialState = {
      isLoading: false,
      options: [],
      selected: [{
        'id': '',
        'email': ''
      }]
  };

  const errorAlertInitalState = {
    show: false,
    message: ''
  };

  const {state} = React.useContext(AuthContext);
  const [validated, setValidated] = React.useState(false);
  const [successAlert, setSuccessAlertState] = React.useState(false);
  const [formParams, setFormParams] = React.useState(formParamsInitialState);
  const [userTerrains, setUserTerrains] = React.useState(null);
  const [terrainDevices, setTerrainDevices] = React.useState(null);
  const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
  const [addUserState, setAddUserState] = React.useState(addUserInitialState);
  const {tableDispatch} = React.useContext(TableContext);
  const {modalDispatch} = React.useContext(ModalContext);
  const [loading, setLoading] = React.useState(false);

  const handleUserSearch = async (query) => {

    setAddUserState({ 
      ...addUserState,
      isLoading: true 
    });

    try {
      const opts = {
        headers: {
          'X-Elaisian-Authorization': state.user.token
        },
        params: {
          filter:  'email,starts_with,' + query,
          'search_type': 'ac'
        }
      };
      const response = await API.get('operations/users', opts);
      if (response.data.status === 'ok') {
        setAddUserState({ 
          ...addUserState,
          isLoading: false,
          options: response.data.result.users
        });
      } else {
        setAddUserState({ 
          ...addUserState,
          isLoading: false 
        });
        console.log("ERROR: ", response.error_code);
      }
    } catch (e) {
      setAddUserState({ 
        ...addUserState,
        isLoading: false 
      });
      console.log(e);
    }
  }

  const getUserTerrains = async (user_id) => {

    try {
      const opts = {
        headers: {
            'X-Elaisian-Authorization': state.user.token
        },
        params: {
            filter:  'user_id,equals,' + user_id
        }
      };
      const response = await API.get('operations/terrains', opts);
      if (response.data.status === 'ok') {
        setUserTerrains(response.data.result.terrains);
      } else {
        console.log("ERROR: ", response.error_code);
      }
    } catch (e) {
        console.log(e);
    }
  }

  const getTerrainDevices = async (terrain_id) => {

    try {
      const opts = {
        headers: {
            'X-Elaisian-Authorization': state.user.token
        }
      };
      const response = await API.get('operations/terrains/' + terrain_id + '/devices', opts);
      if (response.data.status === 'ok') {
        setTerrainDevices(response.data.result);
      } else {
        console.log("ERROR: ", response.error_code);
      }
    } catch (e) {
        console.log(e);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    setLoading(true);

    if (form.checkValidity()) {

      try {
          const token = state.user.token;
          const opts = {
              headers: {
                  'X-Elaisian-Authorization': token
              }
          };

          let response;

          if(props.action === 'edit') {
              response = await API.put('operations/notifications/' + props.id, formParams, opts);
          } else {
              response = await API.post('operations/notifications/', formParams, opts);
          }
          if (response.data.status === 'ok') {
              setSuccessAlertState(true);

              setTimeout(function() {
                modalDispatch({
                    type: 'CLOSE'
                });
                tableDispatch({
                  type: 'DATA',
                  payload: {  
                    table: 'notificationsDetails-' + props.id,
                    data: []
                  }
                });
                tableDispatch({
                  type: 'DATA',
                  payload: {
                      'table': 'notifications',
                      'data': []
                  }
                });
              }, 1500)
          } else {
              setErrorAlertState({
                show: true,
                message: response.error_message
              });
              setLoading(false);
              console.log("ERROR: ", response.error_code);
          }
      } catch (e) {
          setErrorAlertState({
            show: true,
            message: e.response.data.error_message
          });
          setLoading(false);
      }
    } else {
      setValidated(true);
      setLoading(false);
    }
  }

  const getUserData = async (userId, cancelToken) => {
      try {
          const opts = {
            headers: {
              'X-Elaisian-Authorization': state.user.token
            },
            cancelToken: cancelToken
          };
          const response = await API.get('operations/users/' + userId, opts);
          if (response.data.status === 'ok') {
            setAddUserState({ 
              ...addUserState,
              options: [response.data.result],
              selected: [response.data.result]
            });
          } else {
            console.log("ERROR: ", response.error_code);
          }
      } catch (e) {
          console.log(e);
      }
    };

  const getNotificationData = async (cancelToken) => {
    try {
        const opts = {
            headers: {
                'X-Elaisian-Authorization': state.user.token
            },
            cancelToken: cancelToken
        };
        const response = await API.get('operations/notifications/' + props.id, opts);
        if (response.data.status === 'ok') {
            setFormParams(response.data.result);
            getUserData(response.data.result.user_id);
            getUserTerrains(response.data.result.user_id);
            getTerrainDevices(response.data.result.terrain_id);
        } else {
            console.log("ERROR: ", response.error_code);
        }
    } catch (e) {
        console.log(e);
    }
  };

  React.useEffect(() => {
    if(props.action === 'edit') {

        const source = CancelToken.source();

        getNotificationData(source.token);

        return () => {
            source.cancel();
        };
    }
  }, []);

  return (
    !successAlert
    ? <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
        <Form.Row>
          <Form.Group as={Col} xs="4">
            <Form.Label htmlFor="add-user"><b>User</b></Form.Label>
            <AsyncTypeahead
              {...addUserState}
              id="add-user"
              labelKey="email"
              minLength={2}
              onSearch={handleUserSearch}
              onChange={(selected) => {
                setAddUserState({ 
                  ...addUserState,
                  selected: selected
                });
                setFormParams({ 
                  ...formParams,
                  user_id: selected[0] ? selected[0].id : ''
                });
                if(selected[0]) {
                  getUserTerrains(selected[0].id);
                }
              }}
              renderMenuItemChildren={(option, props) => (
                <div key={option.id}>
                  { option.email }
                </div>
              )}
              options={addUserState.options}
              inputProps={{ required: true }}
              selected={addUserState.selected}
            />
          </Form.Group>
          {
            userTerrains && userTerrains.length
            ? 
            <Form.Group as={Col} xs="4">
              <Form.Label htmlFor="add-terrain"><b>User Terrain</b></Form.Label>
              <Form.Control id="add-terrain" as="select" value={formParams.terrain_id || ''} custom onChange={(e) => {
                setFormParams({
                  ...formParams,
                  terrain_id: e.currentTarget.value
                });
                getTerrainDevices(e.currentTarget.value);
              }} required>
                <option label=" "></option>
                {userTerrains.map((terrain, i) => (
                  <option key={i} value={terrain.id}>{terrain.terrain_name}</option>
                ))}
             </Form.Control>
            </Form.Group>
            : <React.Fragment></React.Fragment>
          }
          {
            terrainDevices && terrainDevices.length 
            ? 
            <Form.Group as={Col} xs="4">
              <Form.Label htmlFor="add-device"><b>Terrain Device</b></Form.Label>
              <Form.Control id="add-device" as="select" value={formParams.device_id || ''} custom onChange={(e) => {
                setFormParams({
                  ...formParams,
                  device_id: e.currentTarget.value
                });
              }} required>
                <option label=" "></option>
                {terrainDevices.map((device, i) => (
                  <option key={i} value={device.id}>{device.name}</option>
                ))}
             </Form.Control>
            </Form.Group>
            : <React.Fragment></React.Fragment>
          }
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs="6">
            <Form.Label htmlFor="new-notification_type"><b>Notification Type</b></Form.Label>
            <Form.Control id="new-notification_type" as="select" value={formParams.notification_type || ''} custom onChange={(e) => {
              setFormParams({
                ...formParams,
                notification_type: e.currentTarget.value
              });
            }} required>
              <option label=" "></option>
              {notificationTypes.map((type, i) => (
                <option key={i} value={type.id}>{type.label}</option>
              ))}
           </Form.Control>
          </Form.Group>
          <Form.Group as={Col} xs="6">
            <Form.Label htmlFor="new-notification_level"><b>Notification Type</b></Form.Label>
            <Form.Control id="new-notification_level" as="select" value={formParams.notification_level || ''} custom onChange={(e) => {
              setFormParams({
                ...formParams,
                notification_level: e.currentTarget.value
              });
            }} required>
              <option label=" "></option>
              {notificationLevels.map((type, i) => (
                <option key={i} value={type.id}>{type.label}</option>
              ))}
           </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="new-message">
            <Form.Label><b>Message</b></Form.Label>
            <Form.Control type="text" value={formParams.message || ''} onChange={(e) => {
                setFormParams({
                  ...formParams,
                  message: e.currentTarget.value
                });
            }} required />
          </Form.Group>
        </Form.Row>
        <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
           ...errorAlertState,
           show: false
        })} dismissible>
          <Alert.Heading>Ops! C'è stato un errore!</Alert.Heading>
          <p>
            { errorAlertState.message }
          </p>
        </Alert>
        <Form.Row className="mt-3">
            <Col>
                <Button variant="primary" type="submit" disabled={loading}>
                  <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                  INVIA
                </Button>
            </Col>
        </Form.Row>
      </Form>
    : <Alert variant="success">
        <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
      </Alert>
  );
}

export default ManageNotification;