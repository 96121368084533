import React from 'react';
import BSModal from 'react-bootstrap/Modal';
import { ModalContext } from '../../context/ModalProvider';


const Modal = () => {

	const {modalState, modalDispatch} = React.useContext(ModalContext);

	return (
		modalState.load
		? 
		  <BSModal
		    size={modalState.size}
		    show={modalState.show}
		    onHide={() => modalDispatch({
		    	type: 'CLOSE'
		    })}
		    aria-labelledby="modal"
		  >
		    <BSModal.Header closeButton>
		      <BSModal.Title id="modal">
		        {modalState.title}
		      </BSModal.Title>
		    </BSModal.Header>
		    <BSModal.Body>
		    	{ modalState.load }
		    </BSModal.Body>
		  </BSModal>
		: <React.Fragment></React.Fragment>
	);
}

export default Modal;