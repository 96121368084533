import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import Home from '../pages/home/Home';
import Login from '../pages/login/Login';
import Users from '../pages/users/Users';
import Terrains from '../pages/terrains/Terrains';
import Activities from '../pages/activities/Activities';
import Products from '../pages/activities/Products';
import Devices from '../pages/devices/Devices';
import Alerts from '../pages/alerts/Alerts';
import Leads from '../pages/leads/Leads';
import Notifications from '../pages/notifications/Notifications';
import SystemTools from '../pages/system-tools/SystemTools';
import ProtectedTest from '../pages/test/ProtectedTest';
import Error404 from '../pages/errors/Error404';
import Error401 from '../pages/errors/Error401';
import Layout from '../common/layout/Layout';
import { AuthContext } from '../context/AuthProvider';
import { ConfigProvider } from '../context/ConfigProvider';
import { GlobalProvider } from '../context/GlobalProvider';
import { LayoutProvider } from '../context/LayoutProvider';

const SecretRoute = ({ component: Component, ...rest }) => {

  const { state } = React.useContext(AuthContext);

  return(
    <Route {...rest} render={(props) => (
            state.isAuthenticated
			? <Component {...props} />
			: <Redirect to={{
			  pathname: "/login",
			  state: {
			    from: props.location
			  }
			}} />
    )} />
  )
};

const UnauthRoute = ({ component: Component, ...rest }) => {

  const { state } = React.useContext(AuthContext);

  return(
    <Route {...rest} render={(props) => (
            !state.isAuthenticated
			? <Component {...props} />
			: <Redirect to={{
			  pathname: "/",
			  state: {
			    from: props.location
			  }
			}} />
    )} />
  ) 
};

const Router = () => (
	<ConfigProvider>
	  	<LayoutProvider>
		  	<Layout>
				<UnauthRoute path="/login" exact component={Login} />
				<GlobalProvider>
					<SecretRoute path="/" exact component={Home} />
					<SecretRoute path="/activities/:id?" exact component={Activities} />
					<SecretRoute path="/products/:id?" exact component={Products} />
					<SecretRoute path="/users/:id?" exact component={Users} />
					<SecretRoute path="/terrains/:id?" exact component={Terrains} />
					<SecretRoute path="/devices/:id?" exact component={Devices} />
					<SecretRoute path="/leads/:id?" exact component={Leads} />
					<SecretRoute path="/notifications/:id?" exact component={Notifications} />
					<SecretRoute path="/programs/user/:user_id/terrain/:terrain_id" exact component={Alerts} />
					<SecretRoute path="/system-tools" exact component={SystemTools} />
					<SecretRoute path="/protected/:id?" exact component={ProtectedTest} />
				</GlobalProvider>
			</Layout>
		</LayoutProvider>
	</ConfigProvider>
)

export default Router
