import React from 'react';
import { AuthContext } from '../../context/AuthProvider';
import Table from '../../components/table/Table';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import Alert from "react-bootstrap/Alert";

const ImportGeoJson = (props) => {
    const importJsonInitialState = {
        terrainId: props.terrainId
    };
    const [successAlert, setSuccessAlertState] = React.useState(false);
    const errorAlertInitalState = {
        show: false,
        message: ''
    };
    const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
    const [loading, setLoading] = React.useState(false);
    const [importJsonState, setImportJsonState] = React.useState(importJsonInitialState);
    const {state} = React.useContext(AuthContext);
    const fileJson = React.createRef();
    const {tableDispatch} = React.useContext(TableContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = state.user.token;
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': token
                }
            };
            const formData = new FormData();
            formData.append('file_json', fileJson.current.files[0]);
            const response = await API.post('operations/import/terrains/' + importJsonState.terrainId + "/geo-json", formData, opts);
            if (response.data.status === 'ok') {
                setSuccessAlertState(true);
                setTimeout(function() {
                    setImportJsonState(importJsonInitialState);
                    props.setImportJsonData({
                        show: false,
                        device: null
                    });
                }, 1500);
                tableDispatch({
                    type: 'DATA',
                    payload: {
                        table: 'terrainPolygons-' + props.terrainId,
                        data: []
                    }
                });
                setLoading(false);
                setTimeout(function () {
                    setSuccessAlertState(false);
                }, 1500);
            } else {
                setErrorAlertState({
                    show: true,
                    message: response.error_message
                });
                setLoading(false);
                console.log("ERROR: ", response.error_message);
            }
        } catch (e) {
            setErrorAlertState({
                show: true,
                message: e.response.data.error_message
            });
            setLoading(false);
            console.log(e);
        }
    }

    return(
        !successAlert
            ? <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Import Geo-Json</h5>
                    <form className="m-1 mb-0" onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-auto">
                                <input type="file" className="form-control" id="validatedJsonFile" required ref={fileJson}/>
                            </div>
                        </div>
                        <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
                            ...errorAlertState,
                            show: false
                        })} dismissible>
                            <Alert.Heading>Ops!</Alert.Heading>
                            <p>
                                { errorAlertState.message }
                            </p>
                        </Alert>
                        <div className="form-row">
                            <div className="form-group col-auto">
                                <button type="submit" className="btn btn-primary mr-2" disabled={loading}>
                                    <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                                    Import Geo-Json
                                </button>
                                <button type="reset" className={'btn btn-secondary'} onClick={() => {
                                    setImportJsonState(importJsonInitialState);
                                    props.setImportJsonData({
                                        terrainId: null
                                    });
                                }}>EXIT</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            : <Alert variant="success">
                <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
            </Alert>
    );
}

const TerrainPolygons = (props) => {
    const [importJsonData, setImportJsonData] = React.useState({
        terrainId: null
    });
    const {tableState, tableDispatch} = React.useContext(TableContext);
    const {state, canManage} = React.useContext(AuthContext);

    const terrainDevicesColumns = [
        {
            id: 'id',
            name: 'ID DB',
            type: 'string'
        },{
            id: 'name',
            name: 'Name',
            type: 'string'
        },{
            id: 'hectare_size',
            name: 'Size (Ha)',
            type: 'float'
        },{
            id: 'points_count',
            name: 'Pts',
            type: 'int'
        }/*,{
            id: 'bbox_p1_lat',
            name: 'P1 lat.',
            type: 'float'
        },{
            id: 'bbox_p1_lng',
            name: 'P1 long.',
            type: 'float'
        },{
            id: 'bbox_p2_lat',
            name: 'P2 lat.',
            type: 'float'
        },{
            id: 'bbox_p2_lng',
            name: 'P2 long.',
            type: 'float'
        }*/
    ];

    const getTerrainPolygons = async (cancelToken) => {
        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                cancelToken: cancelToken
            };
            const response = await API.get('operations/terrains/' + props.element.id + '/polygons', opts);
            if (response.data.status === 'ok') {
                if(response.data.result.length) {
                    tableDispatch({
                        type: 'DATA',
                        payload: {
                            table: 'terrainPolygons-' + props.element.id,
                            data: response.data.result
                        }
                    });
                } else {
                    tableDispatch({
                        type: 'EMPTY',
                        payload: {
                            table: 'terrainPolygons-' + props.element.id
                        }
                    });
                }
            } else {
                console.log("ERROR: ", response);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const actions = canManage('terrains','write') ? [
        {
            name: 'Delete',
            className: 'danger',
            call: async (polygon) => {
                let confirm = window.confirm("Are you sure?");
                if (confirm) {
                    try {
                        const opts = {
                            headers: {
                                'X-Elaisian-Authorization': state.user.token
                            }
                        };
                        const response = await API.delete('operations/polygons/' + polygon.id, opts);
                        if (response.data.status === 'ok') {
                            tableDispatch({
                                type: 'DATA',
                                payload: {
                                    table: 'terrainPolygons-' + props.element.id,
                                    data: []
                                }
                            });
                        } else {
                            console.log("ERROR: ", response);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        }
    ] : [];

    React.useEffect(() => {
        const source = CancelToken.source();
        if(!tableState['terrainPolygons-' + props.element.id]) {
            tableDispatch({
                type: 'INIT',
                payload: {
                    table: 'terrainPolygons-' + props.element.id,
                    columns: terrainDevicesColumns,
                    data: [],
                    filters: [],
                    orders: [],
                    hideFilters: true,
                    disableOrder: true
                }
            });
        }
        if(tableState && tableState['terrainPolygons-' + props.element.id] && !tableState['terrainPolygons-' + props.element.id].data.length && !tableState['terrainPolygons-' + props.element.id].empty) {
            getTerrainPolygons(source.token);
        }
        return () => {
            source.cancel();
        };

    }, [tableState['terrainPolygons-' + props.element.id]])

    return (
        <React.Fragment>
            <div className="row">
                {
                    canManage('terrains','write')
                    &&
                    <div className="col">
                        <ImportGeoJson terrainId={props.element.id} setImportJsonData={setImportJsonData} />
                    </div>
                }
                <div className="col-12">
                    <Table table={'terrainPolygons-' + props.element.id} actions={actions} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default TerrainPolygons;