import React from 'react';
import { AuthContext } from '../../context/AuthProvider';
//import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import Table from '../../components/table/Table';
//import 'react-datepicker/dist/react-datepicker.css';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';

const AddDevice = (props) => {

    const addDeviceInitialState = {
        userId: props.userId,
        coordLat: '',
        coordLng: '',
        dateInstall: '', 
        dateStart: null,
        dateEnd: null, 
        selected: [],
        isLoading: false,
        options: []
    };
    
    const [addDeviceState, setAddDeviceState] = React.useState(addDeviceInitialState);
    const {tableDispatch} = React.useContext(TableContext);
    const [loading, setLoading] = React.useState(false);
    const {state} = React.useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = state.user.token;
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': token
                }
            };
            const data = {
                device_id: addDeviceState.selected[0].id
            };
            const response = await API.post('operations/users/' + addDeviceState.userId + '/devices', data, opts);
            if (response.data.status === 'ok') {
                tableDispatch({
                  type: 'DATA',
                  payload: {  
                    table: 'userDevices-' + props.userId,
                    data: []
                  }
                });
                setLoading(false);
            } else {
                console.log("ERROR: ", response.error_code);
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    const handleSearch = async (query) => {

        setAddDeviceState({ 
            ...addDeviceState,
            isLoading: true 
        });

        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                params: {
                    filter:  'device_code,starts_with,' + query,
                    'search_type': 'ac_np'
                }
            };
            const response = await API.get('operations/devices', opts);
            if (response.data.status === 'ok') {
                setAddDeviceState({ 
                    ...addDeviceState,
                    isLoading: false,
                    options: response.data.result.devices
                });
            } else {
                setAddDeviceState({ 
                    ...addDeviceState,
                    isLoading: false 
                });
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            setAddDeviceState({ 
                ...addDeviceState,
                isLoading: false 
            });
            console.log(e);
        }
    }

    return(
        <form className="m-1 mb-0" onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-auto">
                    <AsyncTypeahead
                      {...addDeviceState}
                      id="add-device"
                      className=""
                      labelKey="device_code"
                      minLength={2}
                      onSearch={handleSearch}
                      onChange={(selected) => (
                        setAddDeviceState({ 
                            ...addDeviceState,
                            selected: selected
                        })
                      )}
                      placeholder="Search for a Device..."
                      renderMenuItemChildren={(option, props) => (
                        <div key={option.id}>
                            { option.device_code }
                        </div>
                      )}
                    />
                </div>
                <div className="form-group col-auto">
                    <button className="btn btn-primary" disabled={loading}>
                        <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                        Associate Device
                    </button>
                </div>
            </div>
        </form>
    );
};

const UserDevices = (props) => {

    const {tableState, tableDispatch} = React.useContext(TableContext);
    const {modalDispatch} = React.useContext(ModalContext);
    const {state, canManage} = React.useContext(AuthContext);
    const history = useHistory();

    const userDevicesColumns = [
        {
            id: 'id',
            name: 'ID DB',
            type: 'string'
        },{
            id: 'device_code',
            name: 'ID Device',
            type: 'string'
        },{
            id: 'name',
            name: 'Name',
            type: 'string'
        },{
            id: 'device_type',
            name: 'Type',
            type: 'string'
        },{
            id: 'status',
            name: 'Status',
            type: 'string'
        }/*,{
            id: 'created',
            name: 'Associated',
            type: 'timestamp',
            more: 'info'
        },{
            id: 'date_start',
            name: 'Date Start',
            type: 'timestamp',
            more: 'info'
        }*/
    ];

    const actions = [
        {
            name: 'Details',
            call: (device) => {
                modalDispatch({
                    type: 'CLOSE'
                });
                history.push('/devices/' + device.id);
            }
        }
    ];
    if (canManage('users','write')) {
        actions.push(
            {
                name: 'Disassociate',
                className: 'danger',
                call: async (device) => {
                    var confirm = window.confirm("Are you sure?");
                    if (confirm) {
                        try {
                            const opts = {
                                headers: {
                                    'X-Elaisian-Authorization': state.user.token
                                }
                            };
                            const response = await API.delete('operations/users/' + props.element.id + '/devices/' + device.id, opts);
                            if (response.data.status === 'ok') {
                                tableDispatch({
                                    type: 'DATA',
                                    payload: {
                                        table: 'userDevices-' + props.element.id,
                                        data: []
                                    }
                                });
                            } else {
                                console.log("ERROR: ", response.error_code);
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }
            }
        );
    }

    const getUserDevices = async (cancelToken) => {
        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                cancelToken: cancelToken
            };
            const response = await API.get('operations/users/' + props.element.id + '/devices', opts);
            if (response.data.status === 'ok') {
                if(response.data.result.length) { 
                    tableDispatch({
                      type: 'DATA',
                      payload: {  
                        table: 'userDevices-' + props.element.id,
                        data: response.data.result
                      }
                    });
                } else {
                    tableDispatch({
                        type: 'EMPTY',
                        payload: {  
                          table: 'userDevices-' + props.element.id
                        }
                    });
                }
            } else {
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {

        const source = CancelToken.source();

        if(!tableState['userDevices-' + props.element.id]) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: 'userDevices-' + props.element.id,
                columns: userDevicesColumns,
                data: [],
                filters: [],
                orders: [],
                hideFilters: true,
                disableOrder: true
              }
            });
        }

        if(tableState && tableState['userDevices-' + props.element.id] && !tableState['userDevices-' + props.element.id].data.length && !tableState['userDevices-' + props.element.id].empty) {
            getUserDevices(source.token);
        }

        return () => {
            source.cancel();
        };

    }, [tableState['userDevices-' + props.element.id]])

    return (
        <React.Fragment>
            <div className="row">
                {
                    canManage('users','write')
                    &&
                    <div className="col">
                        <AddDevice userId={props.element.id} />
                    </div>

                }
                <div className="col-12">
                    <Table table={'userDevices-' + props.element.id} actions={actions} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default UserDevices;