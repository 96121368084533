import React from 'react';
//import { AuthContext } from '../../context/AuthProvider';
import Modal from '../../common/modal/Modal';
//import API, { CancelToken } from '../../utils/AjaxUtils';

const SystemTools = () => {

    //const {modalState, modalDispatch} = React.useContext(ModalContext);
    //const {tableState, getTableData, tableDispatch} = React.useContext(TableContext);

    return (
        <div className="container-fluid pb-3">
            <div className="row">
                <div className="col-4">
                    <div className="card">
                        <div className="card-body">
                            
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </div>
    )
}

export default SystemTools;