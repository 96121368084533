import React from 'react';
import ManageUser from './ManageUser';
import UserDevices from './UserDevices';
import UserTerrains from './UserTerrains';
import Modal from '../../common/modal/Modal';
import Table from '../../components/table/Table';
import Details from '../../components/details/Details';
import { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AuthContext } from '../../context/AuthProvider';


const Users = () => {

    const {modalDispatch} = React.useContext(ModalContext);
    const {state, canManage} = React.useContext(AuthContext);
    const {tableState, getTableData, tableDispatch} = React.useContext(TableContext);

    const columns = [
        {
            id: 'email',
            name: 'E-mail',
            type: 'string'
        },{
            id: 'last_name',
            name: 'Last Name',
            type: 'string'
        },{
            id: 'first_name',
            name: 'First Name',
            type: 'string'
        },{
            id: 'language',
            name: 'Language',
            type: 'string'
        },{
            id: 'company',
            name: 'Company',
            type: 'string'
        },{
            id: 'address_country',
            name: 'Address Country',
            type: 'string'
        },{
            id: 'address_city',
            name: 'Address City',
            type: 'string'
        },{
            id: 'id',
            name: 'ID DB',
            type: 'string'
        }/*,{
            id: 'created',
            name: 'Created',
            type: 'timestamp'
        },{
            id: 'last_name',
            type: 'string'
        },{
            id: 'enabled',
            type: 'boolean'
        },{
            id: 'conduction_type',
            type: 'string'
        },{
            id: 'address_street',
            type: 'string'
        },{
            id: 'agronomist',
            type: 'boolean'
        },{
            id: 'updated',
            type: 'timestamp'
        }*/
    ];

    const customColumns = [
        {
            id: 'phone_1',
            name: 'Phone #1',
            type: 'string'
        },{
            id: 'phone_2',
            name: 'Phone #2',
            type: 'string'
        },{
            id: 'offer_type',
            name: 'Offer Type',
            type: 'string'
        },{
            id: 'offer_status',
            name: 'Offer Status',
            type: 'string'
        },{
            id: 'priority',
            name: 'Priority',
            type: 'number'
        }
    ];

    const detailsComponents = [
        {
            'name': 'Terrains',
            'load': <UserTerrains />
        },{
            'name': 'Devices',
            'load': <UserDevices />
        }   
    ];

    const actions = [
        {
            name: 'Details',
            call: (user) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'User Details',
                        size: 'xl',
                        show: true,
                        load: <Details what='operations/users' table='users' id={user.id} components={detailsComponents} customColumns={customColumns} />
                    }
                })
            },
        }
    ];

    if (canManage('users', 'write')) {
        actions.push(
            {
                name: 'EDIT',
                call: (user) => {
                    modalDispatch({
                        type: 'OPEN',
                        payload: {
                            title: 'Edit User',
                            size: 'lg',
                            show: true,
                            load: <ManageUser action="edit" id={user.id} />
                        }
                    })
                },
            }
        );
    }

    React.useEffect(() => {

        const source = CancelToken.source();

        if(!tableState || !tableState.users) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: 'users',
                columns: columns,
                data: [],
                filters: [],
                orders: []
              }
            });
        }

        if(tableState && tableState.users && !tableState.users.data.length) {
            getTableData('operations/users', 'users', source.token);
        }

        return () => {
            source.cancel();
        };
    }, [tableState.users])

    return (
        <div className="container-fluid pb-3">
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {
                                    canManage('users', 'write') ?
                                        <div className="col m-1">
                                            <button className="btn btn-outline-success" onClick={() => {
                                                modalDispatch({
                                                    type: 'OPEN',
                                                    payload: {
                                                        title: 'New User',
                                                        size: 'lg',
                                                        show: true,
                                                        load: <ManageUser />
                                                    }
                                                })
                                            }}>New User</button>
                                        </div> : <React.Fragment />
                                }
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Table table="users" actions={actions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </div>
    )
}

export default Users;