import React from 'react';
import Col from 'react-bootstrap/Col';
import { AuthContext } from '../../context/AuthProvider';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { ConfigContext } from '../../context/ConfigProvider';

const ManageAlert = (props) => {

  const {configState} = React.useContext(ConfigContext);

  const formParamsInitialState = {
    variable: '',
    threshold_min: '',
    threshold_max: '',
    phone: '',
    snooze: '',
    enabled: false
  };

  const errorAlertInitalState = {
    show: false,
    message: ''
  };

  const {state} = React.useContext(AuthContext);
  const [minAndMaxValue, setMinAndMaxValue] = React.useState({min: '', max: ''});
  const [validated, setValidated] = React.useState(false);
  const [successAlert, setSuccessAlertState] = React.useState(false);
  const [formParams, setFormParams] = React.useState(formParamsInitialState);
  const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
  const {tableDispatch} = React.useContext(TableContext);
  const {modalDispatch} = React.useContext(ModalContext);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    setLoading(true);

    if (form.checkValidity()) {

      try {
          const token = state.user.token;
          const opts = {
              headers: {
                  'X-Elaisian-Authorization': token
              }
          };

          let response;

          if(props.action === 'edit') {
              response = await API.put('users/' + props.user_id + '/terrains/' + props.terrain_id + '/programs/' + props.id, formParams, opts);
          } else {
              response = await API.post('users/' + props.user_id + '/terrains/' + props.terrain_id + '/programs', formParams, opts);
          }
          if (response.data.status === 'ok') {
              setSuccessAlertState(true);

              setTimeout(function() {
                modalDispatch({
                    type: 'CLOSE'
                });
                tableDispatch({
                  type: 'DATA',
                  payload: {  
                    table: 'alerts-' + props.terrain_id,
                    data: []
                  }
                });
              }, 1500)
          } else {
              setErrorAlertState({
                show: true,
                message: response.error_message
              });
              setLoading(false);
              console.log("ERROR: ", response.error_code);
          }
      } catch (e) {
          setErrorAlertState({
            show: true,
            message: e.response.data.error_message
          });
          setLoading(false);
      }
    } else {
      setValidated(true);
      setLoading(false);
    }
  }

  const getAlertData = async (cancelToken) => {
    try {
        const opts = {
            headers: {
                'X-Elaisian-Authorization': state.user.token
            },
            cancelToken: cancelToken
        };
        const response = await API.get('users/' + props.user_id + '/terrains/' + props.terrain_id + '/programs/' + props.id, opts);
        if (response.data.status === 'ok') {
            setFormParams(response.data.result);
            selectVariable(response.data.result.variable);
        } else {
            console.log("ERROR: ", response.error_code);
        }
    } catch (e) {
        console.log(e);
    }
  };

  const selectVariable = (value) => {
    let variable = configState.auto_alert.variables.filter(function(v,i) {
      return v.id === value;
    });
    setMinAndMaxValue({
      min: variable[0].threshold_min,
      max: variable[0].threshold_max
    });
  }

  React.useEffect(() => {
    if(props.action === 'edit') {

        const source = CancelToken.source();

        getAlertData(source.token);

        return () => {
            source.cancel();
        };
    }
  }, []);

  return (
    !successAlert
    ? <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
        <Form.Row>
          <Form.Group as={Col} controlId="variable">
            <Form.Label><b>Variable</b></Form.Label>
            <Form.Control as="select" value={formParams.variable || ''} custom onChange={(e) => {
              setFormParams({
                ...formParams,
                variable: e.currentTarget.value
              });
              selectVariable(e.currentTarget.value);
            }} required>
              <option label=" "></option>
              {configState.auto_alert.variables.map((variable, i) => (
                <option key={i} value={variable.id}>{ variable.id + ' (' + variable.unit + ')' }</option>
              ))}
           </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="threshold_min">
            <Form.Label><b>Threshold Min</b></Form.Label>
            <Form.Control type="text" value={formParams.threshold_min || ''} onChange={(e) => {
              setFormParams({
                ...formParams,
                threshold_min: e.currentTarget.value
              });
            }} required/>
            <Form.Text className="text-muted">
              Min. Value: { minAndMaxValue.min }
            </Form.Text>
          </Form.Group>
          <Form.Group as={Col} controlId="threshold_max">
            <Form.Label><b>Threshold Max</b></Form.Label>
            <Form.Control type="text" value={formParams.threshold_max || ''} onChange={(e) => {
              setFormParams({
                ...formParams,
                threshold_max: e.currentTarget.value
              });
            }} required/>
            <Form.Text className="text-muted">
              Max. Value: { minAndMaxValue.max }
            </Form.Text>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="phone">
            <Form.Label><b>Phone</b></Form.Label>
            <Form.Control type="tel" value={formParams.phone || ''} onChange={(e) => {
              setFormParams({
                ...formParams,
                phone: e.currentTarget.value
              });
            }} required/>
          </Form.Group>
          <Form.Group as={Col} controlId="snooze">
            <Form.Label><b>Snooze</b></Form.Label>
            <Form.Control type="text" value={formParams.snooze || ''} onChange={(e) => {
              setFormParams({
                ...formParams,
                snooze: e.currentTarget.value
              });
            }} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Check
              custom
              type="switch"
              label="Enabled"
              id="enabled"
              checked={formParams.enabled}
              onChange={(e) => {
                setFormParams({
                  ...formParams,
                  enabled: e.target.checked
                });
              }}
            />
          </Form.Group>
        </Form.Row>
        <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
           ...errorAlertState,
           show: false
        })} dismissible>
          <Alert.Heading>Ops! C'è stato un errore!</Alert.Heading>
          <p>
            { errorAlertState.message }
          </p>
        </Alert>
        <Form.Row className="mt-3">
            <Col>
                <Button variant="primary" type="submit" disabled={loading}>
                  <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                  INVIA
                </Button>
            </Col>
        </Form.Row>
      </Form>
    : <Alert variant="success">
        <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
      </Alert>
  );
}

export default ManageAlert;