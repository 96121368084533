import React from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { ConfigContext } from '../../context/ConfigProvider';
import { useParams } from 'react-router';
import ManageAlert from './ManageAlert';
import ManageCredits from './ManageCredits';
import Modal from '../../common/modal/Modal';
import Table from '../../components/table/Table';
//import Details from '../../components/details/Details';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';

const Alerts = () => {

    const {state, canManage} = React.useContext(AuthContext);
    const {configState} = React.useContext(ConfigContext);
    const {modalDispatch} = React.useContext(ModalContext);
    const {tableState, tableDispatch} = React.useContext(TableContext);
    const [credits, setCredits] = React.useState({
        credits: '',
        unlimited: false
    });
    const {user_id, terrain_id} = useParams();

    const columns = [
        {
            id: 'phone',
            name: 'Phone',
            type: 'string'
        },{
            id: 'variable',
            name: 'Variable',
            type: 'string'
        },{
            id: 'threshold_min',
            name: 'Threshold Min',
            type: 'string'
        },{
            id: 'threshold_max',
            name: 'Threshold Max',
            type: 'string'
        },{
            id: 'snooze',
            name: 'Snooze',
            type: 'string'
        },{
            id: 'enabled',
            name: 'Enabled',
            type: 'boolean'
        },{
            id: 'id',
            name: 'ID DB',
            type: 'string'
        }
    ];

    //const detailsComponents = [];

    const actions = canManage('alerts', 'write') ? [
        /*{
            name: 'Details',
            call: (program) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Alert Details',
                        size: 'xl',
                        show: true,
                        load: <Details what={'operations/users/' + user_id + '/terrains/' + terrain_id + '/programs'} id={program.id} components={detailsComponents} />
                    }
                });
            },
        },*/{
            name: 'EDIT',
            call: (alert) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Edit Alert',
                        size: 'lg',
                        show: true,
                        load: <ManageAlert action="edit" id={alert.id} user_id={user_id}  terrain_id={terrain_id} />
                    }
                })
            },
        },{
            name: 'DELETE',
            className: 'danger',
            call: async (alert) => {
                var confirm = window.confirm("Are you sure?");
                if (confirm) {
                    try {
                        const opts = {
                            headers: {
                                'X-Elaisian-Authorization': state.user.token
                            }
                        };
                        const response = await API.delete('operations/users/' + user_id + '/terrains/' + terrain_id + '/programs/' + alert.id, opts);
                        if (response.data.status === 'ok') {
                            tableDispatch({
                              type: 'DATA',
                              payload: {  
                                table: 'alerts-' + terrain_id,
                                data: []
                              }
                            });
                        } else {
                            console.log("ERROR: ", response.error_code);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        }
    ] : [];

    const getAlertsData = async (cancelToken) => {
        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                cancelToken: cancelToken
            };
            const response = await API.get('operations/users/' + user_id + '/terrains/' + terrain_id + '/programs', opts);
            if (response.data.status === 'ok') {
                if(response.data.result.length) {
                  tableDispatch({
                      type: 'DATA',
                      payload: {  
                        table: 'alerts-' + terrain_id,
                        data: response.data.result
                      }
                    });
                } else {
                  tableDispatch({
                    type: 'EMPTY',
                    payload: {  
                      table: 'alerts-' + terrain_id
                    }
                  });
                }
            } else {
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getCreditsData = async (cancelToken) => {
        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                cancelToken: cancelToken
            };
            const response = await API.get('operations/users/' + user_id + '/sms-credits', opts);
            if (response.data.status === 'ok') {
                setCredits(response.data.result);
            } else {
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {

        const sourceAlerts = CancelToken.source();
        const sourceCredits = CancelToken.source();

        if(!tableState || !tableState['alerts-' + terrain_id]) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: 'alerts-' + terrain_id,
                columns: columns,
                data: [],
                filters: [],
                orders: [],
                hideFilters: true,
                disableOrder: true
              }
            });
        }

        if(tableState && tableState['alerts-' + terrain_id] && !tableState['alerts-' + terrain_id].data.length && !tableState['alerts-' + terrain_id].empty) {
            getAlertsData(sourceAlerts.token);
        }
        
        getCreditsData(sourceCredits.token);

        return () => {
            sourceAlerts.cancel();
            sourceCredits.cancel();
        };
    }, [tableState['alerts-' + terrain_id]])

    return (
        <div className="container-fluid pb-3">
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {
                                    canManage('alerts', 'write') ?
                                    <div className="col-auto m-1">
                                        <button className="btn btn-outline-success mr-1" onClick={() => {
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'New Alert',
                                                    size: 'sm',
                                                    show: true,
                                                    load: <ManageAlert  user_id={user_id}  terrain_id={terrain_id} />
                                                }
                                            })
                                        }}>New Alert</button> 
                                        <button className="btn btn-outline-primary" onClick={() => {
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'Manage Credits',
                                                    size: 'lg',
                                                    show: true,
                                                    load: <ManageCredits user_id={user_id} credits={credits} terrain_id={terrain_id} />
                                                }
                                            })
                                        }}>Manage Credits</button>
                                    </div> : <React.Fragment />
                                }
                                <div className="col-auto ml-auto mt-1">
                                    <h5>CREDITS: {credits.unlimited ? 'UNLIMITED' : credits.credits}</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Table table={'alerts-' + terrain_id} actions={actions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </div>
    )
}

export default Alerts;