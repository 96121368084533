import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from '../../context/AuthProvider';
import ManageNotification from './ManageNotification';
import Modal from '../../common/modal/Modal';
import Table from '../../components/table/Table';
import Details from '../../components/details/Details';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { ConfigContext } from '../../context/ConfigProvider';

const ImportCSV = (props) => {

    const errorAlertInitalState = {
        show: false,
        message: ''
    };
    
    const [successAlert, setSuccessAlertState] = React.useState(false);
    const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
    const {tableDispatch} = React.useContext(TableContext);
    const {modalDispatch} = React.useContext(ModalContext);
    const [loading, setLoading] = React.useState(false);
    const {state} = React.useContext(AuthContext);
    const fileCSV = React.createRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = state.user.token;
            
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': token
                }
            };

            const formData = new FormData();

            formData.append('file_csv', fileCSV.current.files[0]);

            if(props.import_type) {
                formData.append('import_type', props.import_type);
            }

            const response = await API.post('operations/import/notifications/csv', formData, opts);
            
            if (response.data.status === 'ok') {
                setLoading(false);
                setSuccessAlertState(true);
                setTimeout(function() {
                    modalDispatch({
                        type: 'CLOSE'
                    });
                    tableDispatch({
                      type: 'DATA',
                      payload: {  
                        table: 'notifications',
                        data: [],
                      }
                    });
                    }, 1500)
            } else {

                let message = response.error_message + ': <br>';

                response.details.forEach(function(detail){
                    message += '-' + detail + ';<br>';
                });

                setErrorAlertState({
                    show: true,
                    message: message
                });
                setLoading(false);
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {

            let message = e.response.data.error_message + ': <br>';

                e.response.data.details.forEach(function(detail){
                    message += '-' + detail + ';<br>';
                });

            setErrorAlertState({
                show: true,
                message: message
            });
            setLoading(false);
            console.log(e);
        }
    }

    return(
        !successAlert
        ? 
        <form className="m-1 mb-0" onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-auto">
                    <input type="file" className="form-control" id="validatedCSVFile" required ref={fileCSV}/>
                </div>
            </div>
            <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
                 ...errorAlertState,
                 show: false
              })} dismissible>
                <Alert.Heading>Ops!</Alert.Heading>
                <div dangerouslySetInnerHTML={{ __html: errorAlertState.message }} />
            </Alert>
            <div className="form-row">
                <div className="form-group col-auto">
                    <button type="submit" className="btn btn-primary mr-2" disabled={loading}>
                        <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                        Import CSV
                    </button>
                </div>
            </div>
        </form>
        : <Alert variant="success">
            <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
          </Alert>
    );
}

const Notifications = () => {

    const {configState} = React.useContext(ConfigContext);
    const {modalDispatch} = React.useContext(ModalContext);
    const {tableState, getTableData, tableDispatch} = React.useContext(TableContext);
    const {state, canManage} = React.useContext(AuthContext);

    const columns = [
        {
            id: 'enabled',
            name: 'Enabled',
            type: 'boolean'
        },{
            id: 'notification_type',
            name: 'Type',
            type: 'select',
            options: configState ? configState.notification_type : []
        },{
            id: 'notification_level',
            name: 'Level',
            type: 'select',
            options: configState ? configState.notification_level : []
        },{
            id: 'read',
            name: 'Read',
            type: 'boolean'
        },{
            id: 'message',
            name: 'Message',
            type: 'string'
        },{
            id: 'date_read',
            name: 'Read at',
            type: 'timestamp'
        },{
            id: 'device_id',
            name: 'Device',
            type: 'autocomplete',
            url: '/operations/devices',
            paramName: 'device_code',
            labelKey: 'device_code',
            displayName: 'device_code',
            result: 'devices',
            showInTable: 'device_code'
        },{
            id: 'terrain_id',
            name: 'Terrain',
            type: 'autocomplete',
            url: '/operations/terrains',
            paramName: 'terrain_name',
            labelKey: 'terrain_name',
            displayName: 'terrain_name',
            result: 'terrains',
            showInTable: 'terrain_name'
        },{
            id: 'user_id',
            name: 'E-mail',
            type: 'autocomplete',
            url: '/operations/users',
            paramName: 'email',
            labelKey: 'email',
            displayName: 'email',
            result: 'users',
            showInTable: 'email'
        },{
            id: 'date_completed',
            name: 'Completed',
            type: 'timestamp'
        },{
            id: 'created',
            name: 'Created',
            type: 'timestamp'
        },{
            id: 'id',
            name: 'ID DB',
            type: 'string'
        }
    ];

    const detailsComponents = [];

    const actions = canManage('advices', 'write') ? [
        {
            name: 'Details',
            call: (notification) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Notification Details',
                        size: 'xl',
                        show: true,
                        load: <Details what='operations/notifications' table='notifications' id={notification.id} components={detailsComponents} />
                    }
                });
            },
        },{
            name: 'EDIT',
            call: (notification) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Edit Notification',
                        size: 'lg',
                        show: true,
                        load: <ManageNotification action="edit" id={notification.id} />
                    }
                })
            },
        },{
            name: 'DISABLE',
            className: 'danger',
            call: async (notification) => {
                var confirm = window.confirm("Are you sure?");
                if (confirm) {
                    try {
                        const opts = {
                            headers: {
                                'X-Elaisian-Authorization': state.user.token
                            }
                        };
                        const response = await API.delete('operations/notifications/'+ notification.id, opts);
                        if (response.data.status === 'ok') {
                            tableDispatch({
                              type: 'DATA',
                              payload: {  
                                table: 'notifications',
                                data: []
                              }
                            });
                        } else {
                            console.log("ERROR: ", response.error_code);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        }
    ] : [];

    React.useEffect(() => {

        const source = CancelToken.source();

        if(!tableState || !tableState.notifications && configState) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: 'notifications',
                columns: columns,
                data: [],
                filters: [],
                orders: []
              }
            });
        }

        if(tableState && tableState.notifications && !tableState.notifications.data.length) {
            getTableData('operations/notifications', 'notifications', source.token);
        }

        return () => {
            source.cancel();
        };
    }, [tableState.notifications, configState])

    return (
        <div className="container-fluid pb-3">
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {
                                    canManage('advices', 'write') ?
                                    <div className="col m-1">
                                        <button className="btn btn-outline-success" onClick={() => {
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'New Notification',
                                                    size: 'lg',
                                                    show: true,
                                                    load: <ManageNotification />
                                                }
                                            })
                                        }}>New Notification</button> &nbsp;
                                        <button className="btn btn-outline-info" onClick={() => {
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'Import CSV',
                                                    size: 'md',
                                                    show: true,
                                                    load: <ImportCSV />
                                                }
                                            })
                                        }}>Import CSV</button> &nbsp;
                                        <button className="btn btn-outline-info" onClick={() => {
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'Import Storico CSV',
                                                    size: 'md',
                                                    show: true,
                                                    load: <ImportCSV import_type="history" />
                                                }
                                            })
                                        }}>Import Storico CSV</button>
                                    </div> : <React.Fragment />
                                }
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Table table="notifications" actions={actions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </div>
    )
}

export default Notifications;