import React from 'react';
import Col from 'react-bootstrap/Col';
import { AuthContext } from '../../context/AuthProvider';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { ConfigContext } from '../../context/ConfigProvider';

const ManageUser = (props) => {

    const passwordRequired = props.action !== 'edit';

    const formParamsInitialState = {
      first_name: '',
      last_name: '',
      email: '',
      phone_1: '',
      phone_2: '',
      language: '',
      company: '',
      company_type: '',
      address_street: '',
      address_city: '',
      address_country: '',
      conduction_type: '',
      oil_mill: false,
      agronomist: false,
      enabled: false,
      priority: '',
      offer_type: '',
      offer_status: '',
    };

    const errorAlertInitalState = {
        show: false,
        message: ''
    };

    const {state} = React.useContext(AuthContext);
    const [validated, setValidated] = React.useState(false);
    const [successAlert, setSuccessAlertState] = React.useState(false);
    const [formParams, setFormParams] = React.useState(formParamsInitialState);
    const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
    const [loading, setLoading] = React.useState(false);
    const {tableDispatch} = React.useContext(TableContext);
    const {modalDispatch} = React.useContext(ModalContext);
    const {configState} = React.useContext(ConfigContext);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const form = e.currentTarget;
      if (form.checkValidity()) {
          try {
              const token = state.user.token;
              const opts = {
                  headers: {
                      'X-Elaisian-Authorization': token
                  }
              };
              let response;
              if (props.action === 'edit') {
                  response = await API.put('operations/users/' + props.id, formParams, opts);
              } else {
                  response = await API.post('operations/users/', formParams, opts);
              }
              if (response.data.status === 'ok') {
                  setSuccessAlertState(true);
                  setTimeout(function() {
                      modalDispatch({
                          type: 'CLOSE'
                      });
                      tableDispatch({
                        type: 'DATA',
                        payload: {  
                          table: 'usersDetails-' + props.id,
                          data: []
                        }
                      });
                      tableDispatch({
                          type: 'DATA',
                          payload: {
                              'table': 'users',
                              'data': []
                          }
                      });
                  }, 1500)
              } else {
                  setLoading(false);
                  setErrorAlertState({
                      show: true,
                      message: response.error_message
                  });
                  console.log("ERROR: ", response.error_code);
              }
          } catch (e) {
              setLoading(false);
              setErrorAlertState({
                  show: true,
                  message: e.response.data.error_message
              });
          }
      } else {
          setLoading(false);
          setValidated(true);
      }
    }

    const getUserData = async (cancelToken) => {
        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                cancelToken: cancelToken
            };
            const response = await API.get('operations/users/' + props.id, opts);
            if (response.data.status === 'ok') {
                setFormParams(response.data.result);
            } else {
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {
        if(props.action === 'edit') {
            const source = CancelToken.source();
            getUserData(source.token);
            return () => {
                source.cancel();
            };
        }
    }, []);

    return (
        !successAlert
        ?  <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
              <Form.Row>
                <Form.Group as={Col} xs="4" controlId="new-first_name">
                  <Form.Label><b>First Name</b></Form.Label>
                  <Form.Control type="text" value={formParams.first_name || ''} onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      first_name: e.currentTarget.value
                    });
                  }} />
                </Form.Group>

                <Form.Group as={Col} xs="4" controlId="new-last_name">
                  <Form.Label><b>Last Name</b></Form.Label>
                  <Form.Control type="text" value={formParams.last_name || ''} onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      last_name: e.currentTarget.value
                    });
                  }} />
                </Form.Group>

                <Form.Group as={Col} xs="4" controlId="new-language">
                  <Form.Label><b>Language</b></Form.Label>
                    <Form.Control as="select" value={formParams.language || ''} custom onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            language: e.currentTarget.value
                        });
                    }} required>
                        <option label=" "></option>
                        {configState.language.map((language, i) => (
                            <option key={i} value={language.id}>{language.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                  <Form.Group as={Col} xs="4" controlId="new-user_offer_type">
                      <Form.Label><b>Offer Type</b></Form.Label>
                      <Form.Control as="select" value={formParams.offer_type || ''} custom onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              offer_type: e.currentTarget.value
                          });
                      }}>
                          <option label=" "></option>
                          {configState.offer_type.map((uot, i) => (
                              <option key={i} value={uot.id}>{uot.label}</option>
                          ))}
                      </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} xs="4" controlId="new-user_offer_status">
                      <Form.Label><b>Offer Status</b></Form.Label>
                      <Form.Control as="select" value={formParams.offer_status || ''} custom onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              offer_status: e.currentTarget.value
                          });
                      }}>
                          <option label=" "></option>
                          {configState.offer_status.map((uos, i) => (
                              <option key={i} value={uos.id}>{uos.label}</option>
                          ))}
                      </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} xs="4" controlId="new-relevance">
                      <Form.Label><b>Priority</b></Form.Label>
                      <Form.Control type="text" value={formParams.priority || ''} onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              priority: e.currentTarget.value
                          });
                      }} />
                  </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="new-email">
                  <Form.Label><b>E-mail</b></Form.Label>
                  <Form.Control type="email" value={formParams.email || ''} onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      email: e.currentTarget.value
                    });
                  }} required />
                </Form.Group>
                <Form.Group as={Col} controlId="new-password">
                  <Form.Label><b>Password</b></Form.Label>
                  <Form.Control type="password" value={formParams.password || ''} onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      password: e.currentTarget.value
                    });
                  }} required={passwordRequired} autoComplete="new-password" />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="new-phone_1">
                  <Form.Label><b>Phone</b></Form.Label>
                  <Form.Control type="tel" value={formParams.phone_1 || ''} onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      phone_1: e.currentTarget.value
                    });
                  }} />
                </Form.Group>

                <Form.Group as={Col} controlId="new-phone_2">
                  <Form.Label><b>Phone 2</b></Form.Label>
                  <Form.Control type="tel" value={formParams.phone_2 || ''} onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      phone_2: e.currentTarget.value
                    });
                  }} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} xs="6" controlId="new-address_street">
                  <Form.Label><b>Address Street</b></Form.Label>
                  <Form.Control type="text" value={formParams.address_street || ''} onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      address_street: e.currentTarget.value
                    });
                  }} />
                </Form.Group>

                <Form.Group as={Col} controlId="new-address_city">
                  <Form.Label><b>Address City</b></Form.Label>
                  <Form.Control type="text" value={formParams.address_city || ''} onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      address_city: e.currentTarget.value
                    });
                  }} />
                </Form.Group>

                <Form.Group as={Col} controlId="new-address_country">
                  <Form.Label><b>Address Country</b></Form.Label>
                  <Form.Control type="text" value={formParams.address_country || ''} onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      address_country: e.currentTarget.value
                    });
                  }} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="new-company">
                  <Form.Label><b>Company</b></Form.Label>
                  <Form.Control className="mt-2" type="text" value={formParams.company || ''} onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      company: e.currentTarget.value
                    });
                  }} />
                </Form.Group>

                <Form.Group as={Col} controlId="new-company_type">
                  <Form.Label><b>Company Type</b></Form.Label>
                  <Form.Control as="select" value={formParams.company_type || ''} custom onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      company_type: e.currentTarget.value
                    });
                  }}>
                      <option label=" "></option>
                      {configState.company_type.map((companyType, i) => (
                        <option key={i} value={companyType.id}>{companyType.name}</option>
                      ))}
                   </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="new-conduction_type">
                  <Form.Label><b>Conduction Type</b></Form.Label>
                  <Form.Control as="select" value={formParams.conduction_type || ''} custom onChange={(e) => {
                    setFormParams({
                      ...formParams,
                      conduction_type: e.currentTarget.value
                    });
                  }}>
                      <option label=" "></option>
                      {configState.conduction_type.map((conductionType, i) => (
                        <option key={i} value={conductionType.id}>{conductionType.name}</option>
                      ))}
                   </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Check
                      custom
                      type="switch"
                      label="OIL MILL"
                      id="new-oil_mill"
                      checked={formParams.oil_mill}
                      onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              oil_mill: e.target.checked
                          });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Check
                      custom
                      type="switch"
                      label="Agronomist"
                      id="new-agronomist"
                      checked={formParams.agronomist}
                      onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              agronomist: e.target.checked
                          });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Check
                      custom
                      type="switch"
                      label="Enabled"
                      id="new-enabled"
                      checked={formParams.enabled}
                      onChange={(e) => {
                          setFormParams({
                              ...formParams,
                              enabled: e.target.checked
                          });
                      }}
                    />
                  </Form.Group>
              </Form.Row>

              <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
                 ...errorAlertState,
                 show: false
              })} dismissible>
                <Alert.Heading>Ops! C'è stato un errore!</Alert.Heading>
                <p>
                  { errorAlertState.message }
                </p>
              </Alert>
              <Form.Row className="mt-3">
                  <Col>
                      <Button variant="primary" type="submit" disabled={loading}>
                        <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                        INVIA
                      </Button>
                  </Col>
              </Form.Row>
          </Form>
        : <Alert variant="success">
                <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
          </Alert>
    );
}

export default ManageUser;