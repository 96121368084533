import React, { useState } from 'react';
import { Pagination, Card, Badge, Container, Row, Col, Form } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthProvider';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { MdPerson, MdEvent, MdBugReport } from 'react-icons/md';
import RichTextEditor from 'react-rte';
import moment from 'moment';

const ManageLeadNotes = (props) => {

    const LeadCreate = (props) => {

        const [editorState, setEditorState] = useState(
            () => RichTextEditor.createEmptyValue()
        );

        const handleSubmit = async (e) => {
            e.preventDefault();
            try {
                const opts = {
                    headers: {
                        'X-Elaisian-Authorization': state.user.token
                    }
                };
                const url = 'sales/leads/' + props.id + '/notes';
                const response = await API.post(url, {
                    message: editorState.toString('html')
                }, opts);
                if (response.data.status === 'ok') {
                    openModal('notes');
                } else {
                    console.log("ERROR: ", response.error_code);
                }
            } catch (e) {
                console.log(e);
            }
        }

        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label>Testo nota:</Form.Label>
                                <RichTextEditor
                                    value={editorState}
                                    onChange={setEditorState}
                                />
                            </Form.Group>
                            <button type='submit' className="btn btn-outline-info">SAVE NOTE</button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
    
    const LeadNotes = (props) => {
        const initialPaginateState = {
            page_index: 1,
            page_size: 5,
            total: 0
        };
        const initialResultPageState = {
            total: 0,
            notes: []
        };
        const [paginateState, setPaginateState] = useState(initialPaginateState);
        const [resultPageState, setResultPageState] = useState(initialResultPageState);
    
        const NoteItem = (props) => {
            const note = props.data;
            return (
                <Row>
                    <Col>
                        <Card border='secondary' style={{ margin: '20px' }}>
                            <Card.Header>
                                <Container fluid>
                                    <Row>
                                        <Col><h4><MdPerson /> <Badge variant='info'>{note.admin_display_name}</Badge></h4></Col>
                                        <Col md='auto'><h4><MdEvent /> <Badge variant='light'>{moment(note.date_created).format('YYYY-mm-DD')}</Badge></h4></Col>
                                    </Row>
                                </Container>
                            </Card.Header>
                            <Card.Body style={{ padding: '0px' }}>
                                <Container fluid>
                                    <Row style={{ margin: '15px' }}>
                                        <Col><Card.Text dangerouslySetInnerHTML={{__html: note.content}}></Card.Text></Col>
                                    </Row>
                                    <Row>
                                        <Col><span className='float-right' style={{ color: '#bbbbbb' }}><MdBugReport />{note.id}</span></Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            );
        };
        
        const NoteList = () => {
            let items = [];
            for (let i = 0; i < resultPageState.notes.length; i++) {
                let n = resultPageState.notes[i];
                items.push(
                    <NoteItem data={n} key={n.id} />
                );
            }
    
            return (
                <Container fluid>{items}</Container>
            );
        };
    
        const Paginator = () => {
            let size = paginateState.page_size || 5;
            let items = [];
            let numPages = Math.ceil(resultPageState.total / size);
            for (let number = 1; number <= numPages; number++) {
                items.push(
                    <Pagination.Item key={number} active={number === paginateState.page_index} onClick={ (e) => {
                        const idx = parseInt(e.target.textContent);
                        setPaginateState({
                            ...paginateState,
                            page_index: idx
                        });
                    }}>
                        {number}
                    </Pagination.Item>
                );
            }
        
            return (
                <Container fluid>
                    <Row>
                        <Col><Pagination>{items}</Pagination></Col>
                    </Row>
                </Container>
            );
        };
    
        const getLeadNotes = async (cancelToken) => {
            try {
                const opts = {
                    headers: {
                        'X-Elaisian-Authorization': state.user.token
                    },
                    cancelToken: cancelToken,
                    params: paginateState
                };
                const url = 'sales/leads/' + props.id + '/notes';
                const response = await API.get(url, opts);
                if (response.data.status === 'ok') {
                    setResultPageState(response.data.result);
                } else {
                    console.log("ERROR: ", response.error_code);
                }
            } catch (e) {
                console.log(e);
            }
        };
    
        React.useEffect(() => {
            const source = CancelToken.source();
            getLeadNotes(source.token);
            return () => {
                source.cancel();
            };
        }, [paginateState]);
    
        return (
            paginateState ? 
            <Container fluid>
                <Row style={{ margin: '20px' }}>
                    <Col><Paginator /></Col>
                    <Col md='auto'>
                        <button className="btn btn-outline-info"onClick = { (e) => {
                            openModal('create');
                        }}>Aggiungi Nota</button>
                    </Col>
                </Row>
                <Row>
                    <Col><NoteList /></Col>
                </Row>
                <Row style={{ margin: '20px' }}>
                    <Col><Paginator /></Col>
                </Row>
            </Container>
            : <Container fluid>
                <Row>
                    <Col><b>LOADING NOTES...</b></Col>
                </Row>
            </Container>
        );
    }
    
    const initialModalState = {
        notesOpened: true
    };

    const {state} = React.useContext(AuthContext);
    const [modalState, setModalState] = useState(initialModalState);

    function openModal(modalType) {
        if (modalType === "notes") {
            setModalState({
                notesOpened: true
            });
        } else if (modalType === "create") {
            setModalState({
                notesOpened: false
            });
        }
    };

    React.useEffect(() => {
        const source = CancelToken.source();
        
        return () => {
            source.cancel();
        };
    }, [modalState]);

    return (
        modalState ? (
            modalState.notesOpened ?
                <LeadNotes id={props.id} /> :
                <LeadCreate id={props.id} />
        ) : <Container fluid>
            <Row>
                <Col><b>LOADING CONTENT...</b></Col>
            </Row>
        </Container>
    )
}

export default ManageLeadNotes;