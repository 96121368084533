import React from 'react';

export const LayoutContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        wrapperClass: state.wrapperClass === 'toggled' ? '' : 'toggled',
      };
    case "SCROLL":
      return {
          ...state,
          scrollTop: action.payload.scrollTop
        };
    default:
      return state;
  }
};

export const LayoutProvider = ({ children }) => {

  const initialState = {
    wrapperClass: '',
    scrollTop: 0
  };

  const [layoutState, layoutDispatch] = React.useReducer(reducer, initialState);

  return (
    <LayoutContext.Provider
      value={{
        layoutDispatch,
        layoutState
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
};