import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from '../../context/AuthProvider';
import Modal from '../../common/modal/Modal';
import Table from '../../components/table/Table';
import Details from '../../components/details/Details';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { ConfigContext } from '../../context/ConfigProvider';
import ManageLead from './ManageLead';
import ManageLeadNotes from './ManageLeadNotes';

const ImportCSV = (props) => {

    const errorAlertInitalState = {
        show: false,
        message: ''
    };
    
    const [successAlert, setSuccessAlertState] = React.useState(false);
    const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
    const {tableDispatch} = React.useContext(TableContext);
    const {modalDispatch} = React.useContext(ModalContext);
    const [loading, setLoading] = React.useState(false);
    const {state} = React.useContext(AuthContext);
    const fileCSV = React.createRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = state.user.token;
            
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': token
                }
            };

            const formData = new FormData();

            formData.append('file_csv', fileCSV.current.files[0]);

            if(props.import_type) {
                formData.append('import_type', props.import_type);
            }

            const response = await API.post('sales/import/csv/leads', formData, opts);
            
            if (response.data.status === 'ok') {
                setLoading(false);
                setSuccessAlertState(true);
                setTimeout(function() {
                    modalDispatch({
                        type: 'CLOSE'
                    });
                    tableDispatch({
                      type: 'DATA',
                      payload: {  
                        table: 'leads',
                        data: [],
                      }
                    });
                    }, 1500)
            } else {

                let message = response.error_message + ': <br>';

                response.details.forEach(function(detail){
                    message += '-' + detail + ';<br>';
                });

                setErrorAlertState({
                    show: true,
                    message: message
                });
                setLoading(false);
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {

            let message = e.response.data.error_message + ': <br>';

            e.response.data.details.forEach(function(detail){
                message += '-' + detail + ';<br>';
            });

            setErrorAlertState({
                show: true,
                message: message
            });
            setLoading(false);
            console.log(e);
        }
    }

    return(
        !successAlert
        ? 
        <form className="m-1 mb-0" onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-auto">
                    <input type="file" className="form-control" id="validatedCSVFile" required ref={fileCSV}/>
                </div>
            </div>
            <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
                 ...errorAlertState,
                 show: false
              })} dismissible>
                <Alert.Heading>Ops!</Alert.Heading>
                <div dangerouslySetInnerHTML={{ __html: errorAlertState.message }} />
            </Alert>
            <div className="form-row">
                <div className="form-group col-auto">
                    <button type="submit" className="btn btn-primary mr-2" disabled={loading}>
                        <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                        Import CSV
                    </button>
                </div>
            </div>
        </form>
        : <Alert variant="success">
            <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
          </Alert>
    );
}

const Leads = () => {

    const {configState} = React.useContext(ConfigContext);
    const {modalDispatch} = React.useContext(ModalContext);
    const {tableState, getTableData, tableDispatch} = React.useContext(TableContext);
    const {state, canManage} = React.useContext(AuthContext);
    const [statusOptions, setStatusOptions] = React.useState(false);

    const columns = [
        {
            id: 'internal_id',
            name: 'ID',
            type: 'string'
        },
        {
            id: 'channel',
            name: 'Origine',
            type: 'string'
        },{
            id: 'crop_type',
            name: 'O/V',
            type: 'select',
            options: configState && configState.leads ? configState.leads.crop_type : []
        },{
            id: 'admin_user_id',
            name: 'Responsabile',
            type: 'autocomplete',
            url: '/admin-users',
            paramName: 'display_name',
            labelKey: 'display_name',
            displayName: 'display_name',
            result: 'users',
            showInTable: 'admin_display_name'
        },{
            id: 'date_start',
            name: 'Date',
            type: 'timestamp'
        },{
            id: 'status',
            name: 'Status',
            type: 'select',
            options: configState && configState.leads ? configState.leads.status : [],
            classNames: {
                '0' : 'px-4 py-1 bg-warning text-white rounded',
                '1' : 'px-4 py-1 bg-app text-dark rounded',
                '2' : 'px-4 py-1 bg-success text-white rounded',
                '3' : 'px-4 py-1 bg-light text-dark rounded',
                '4' : 'px-4 py-1 bg-cs text-white rounded',
                '5' : 'px-4 py-1 bg-info text-white rounded',
                '6' : 'px-4 py-1 bg-danger text-white rounded',
                '7' : 'px-4 py-1 bg-secondary text-white rounded',
                '8' : 'px-4 py-1 bg-light text-dark rounded',
                '9' : 'px-4 py-1 bg-recall text-white rounded',
                '10' : 'px-4 py-1 bg-cs text-white rounded',
                '11' : 'px-4 py-1 bg-light text-dark rounded'
            }
        },{
            id: 'first_name',
            name: 'Nome',
            type: 'string'
        },{
            id: 'last_name',
            name: 'Cognome',
            type: 'string'
        },{
            id: 'phone',
            name: 'Telefono',
            type: 'string'
        },{
            id: 'email',
            name: 'E-mail',
            type: 'string'
        },{
            id: 'address_city',
            name: 'Città',
            type: 'string'
        }
    ];

    const detailsComponents = [];

    const actions = canManage('sales', 'write') ? [
        {
            name: 'Det.',
            iconType: 'FaSearch',
            call: (lead) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Lead Details',
                        size: 'xl',
                        show: true,
                        load: <Details what='sales/leads/' table='leads' id={lead.id} components={detailsComponents} />
                    }
                });
            }
        },{
            name: 'Edit',
            iconType: 'FaEdit',
            call: (lead) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Edit Lead',
                        size: 'lg',
                        show: true,
                        load: <ManageLead action="edit" id={lead.id} />
                    }
                })
            }
        },{
            name: 'Note',
            iconType: 'FaList',
            call: (lead) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Lead Notes',
                        size: 'xl',
                        show: true,
                        load: <ManageLeadNotes id={lead.id} />
                    }
                });
            }
        }
    ] : [];

    React.useEffect(() => {

        const source = CancelToken.source();

        if((!tableState || !tableState.leads) && configState) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: 'leads',
                columns: columns,
                data: [],
                filters: [],
                orders: []
              }
            });
        }

        if(tableState && tableState.leads && !tableState.leads.data.length) {
            getTableData('sales/leads', 'leads', source.token);
        }

        return () => {
            source.cancel();
        };
    }, [tableState.leads, configState])

    return (
        <div className="container-fluid pb-3">
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {
                                    canManage('sales', 'write') ?
                                    <div className="col m-1">
                                        <button className="btn btn-outline-success" onClick={() => {
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'New Lead',
                                                    size: 'lg',
                                                    show: true,
                                                    load: <ManageLead />
                                                }
                                            })
                                        }}>New Lead</button> &nbsp;
                                        <button className="btn btn-outline-info" onClick={() => {
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'Import CSV',
                                                    size: 'md',
                                                    show: true,
                                                    load: <ImportCSV />
                                                }
                                            })
                                        }}>Import CSV</button> &nbsp;
                                        
                                    </div> : <React.Fragment />
                                }
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Table table="leads" actions={actions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </div>
    )
}

export default Leads;