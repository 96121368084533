import React from 'react';

export const ModalContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "OPEN":

      return {
        ...state,
        ...action.payload,
        show: true,
      };
    case "CLOSE":

      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
};

export const ModalProvider = ({ children }) => {

  const initialState = {
    'size': 'xl', // || sm
    'show': false,
    'load': null,
    'title': null,
  };

  const [modalState, modalDispatch] = React.useReducer(reducer, initialState);

  return (
    <ModalContext.Provider
      value={{
        modalDispatch,
        modalState
      }}
    >
      {children}
    </ModalContext.Provider>
  )
};