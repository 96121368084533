import React from 'react';
import { useParams } from 'react-router';
import ManageDevice from './ManageDevice';
import Modal from '../../common/modal/Modal';
import Table from '../../components/table/Table';
import Details from '../../components/details/Details';
import { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AuthContext } from '../../context/AuthProvider';

const Devices = () => {

    const {modalDispatch} = React.useContext(ModalContext);
    const {state, canManage} = React.useContext(AuthContext);
    const {tableState, getTableData, tableDispatch} = React.useContext(TableContext);
    const {id} = useParams();

    const columns = [
        {
            id: 'device_code',
            name: 'ID Device',
            type: 'string'
        },{
            id: 'name',
            name: 'Name',
            type: 'string'
        },{
            id: 'device_type',
            name: 'Type',
            type: 'select',
            options: [
                {
                  name: '32CONNECT V1',
                  value: '32connect_v1'
                },{
                  name: '32CONNECT V2',
                  value: '32connect_v2'
                },{
                  name: 'PESSL',
                  value: 'pessl'
                },{
                  name: 'PESSL RAIN',
                  value: 'pessl_rain'
                },{
                  name: 'VIRTUAL',
                  value: 'virtual'
                },{
                  name: 'TEST',
                  value: 'test'
                },{
                  name: 'CLONE',
                  value: 'clone'
                }
            ]
        },{
            id: 'status',
            name: 'Status',
            type: 'select',
            options: [
                {
                    name: 'NEW',
                    value: 'new'
                },{
                    name: 'INSTALLED',
                    value: 'installed'
                },{
                    name: 'RUNNING',
                    value: 'running'
                },{
                    name: 'STOPPED',
                    value: 'stopped'
                },{
                    name: 'SLEEPING',
                    value: 'sleeping'
                },{
                    name: 'UNINSTALLED',
                    value: 'uninstalled'
                },{
                    name: 'ERROR',
                    value: 'error'
                },
            ]
        },{
            id: 'id',
            name: 'ID DB',
            type: 'string'
        }
    ];

    const detailsComponents = [];

    const actions = [
        {
            name: 'Details',
            call: (device) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Device Details',
                        size: 'xl',
                        show: true,
                        load: <Details what='operations/devices' table='devices' id={device.id} components={detailsComponents} />
                    }
                });
            },
        }
    ];

    if (canManage('devices', 'write')) {
        actions.push(
            {
                name: 'EDIT',
                call: (device) => {
                    modalDispatch({
                        type: 'OPEN',
                        payload: {
                            title: 'Edit Device',
                            size: 'lg',
                            show: true,
                            load: <ManageDevice action="edit" id={device.id} />
                        }
                    })
                },
            }
        );
    }

    React.useEffect(() => {

        if(id) {
            modalDispatch({
                type: 'OPEN',
                payload: {
                    title: 'Device Details',
                    size: 'xl',
                    show: true,
                    load: <Details what='operations/devices' table='devices' id={id} components={detailsComponents} />
                }
            });
        }

        const source = CancelToken.source();

        if(!tableState || !tableState.devices) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: 'devices',
                columns: columns,
                data: [],
                filters: [],
                orders: []
              }
            });
        }

        if(tableState && tableState.devices && !tableState.devices.data.length) {
            getTableData('operations/devices', 'devices', source.token);
        }

        return () => {
            source.cancel();
        };
    }, [tableState.devices])

    return (
        <div className="container-fluid pb-3">
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {
                                    canManage('devices', 'write') ? 
                                    <div className="col m-1">
                                        <button className="btn btn-outline-success" onClick={() => {
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'New Device',
                                                    size: 'lg',
                                                    show: true,
                                                    load: <ManageDevice />
                                                }
                                            })
                                        }}>New Device</button>
                                    </div> : <React.Fragment />
                                }
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Table table="devices" actions={actions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </div>
    )
}

export default Devices;