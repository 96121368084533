import React from 'react';
import API from '../utils/AjaxUtils';
import { AuthContext } from '../context/AuthProvider';

export const TableContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT":

      return {
        ...state,
        [action.payload.table]: {
          columns: action.payload.columns,
          data: action.payload.data,
          filters: action.payload.filters,
          orders: action.payload.orders,
          keepFilters: (action.payload.keepFilters) ? action.payload.keepFilters : [],
          hideFilters: (action.payload.hideFilters) ? action.payload.hideFilters : false,
          disableOrder: (action.payload.disableOrder) ? action.payload.disableOrder : false,
          hidePagination: (action.payload.hidePagination) ? action.payload.hidePagination : false,
          pageIndex: 1,
          pageSize: 50,
          total: 0,
          empty: false
        }
      };
    case "DATA":

      return {
        ...state,
        [action.payload.table]: {
          ...state[action.payload.table],
          data: action.payload.data,
          total: action.payload.total,
          empty: false
        }
      };
    case "ORDER":

      const orders = state[action.payload.table].orders.concat(action.payload.order);

      return {
        ...state,
        [action.payload.table]: {
          ...state[action.payload.table],
          data: [],
          orders: orders
        }
      };
    case "VERSE":

      const oldOrders = state[action.payload.table].orders;
      const updatedOrder = action.payload.order;
      const orderIndex = oldOrders.findIndex((obj => obj.name === updatedOrder.name));

      if(oldOrders[orderIndex].verse === 'desc' && updatedOrder.verse === 'asc') {
        oldOrders.splice(orderIndex, 1);
      } else {
         oldOrders[orderIndex] = updatedOrder; 
      }

      return {
        ...state,
        [action.payload.table]: {
          ...state[action.payload.table],
          data: [],
          orders: oldOrders
        }
      };
     case "FILTER":

      let filters = [];

      if(state[action.payload.table].keepFilters.length) {

        filters = state[action.payload.table].keepFilters.map((includedFilter) => {

          const filterIndex = state[action.payload.table].filters.findIndex((filter => filter.name === includedFilter));

          return state[action.payload.table].filters[filterIndex];
        });
      }
   
      return {
        ...state,
        [action.payload.table]: {
          ...state[action.payload.table],
          data: [],
          filters: action.payload.filters.concat(filters),
          empty: false
        }
      };
     case "PAGINATE":
   
      return {
        ...state,
        [action.payload.table]: {
          ...state[action.payload.table],
          data: [],
          pageIndex: action.payload.pageIndex,
          //pageSize: action.payload.pageSize,
          total: action.payload.total,
          empty: false
        }
      };
    case "RESET":

      let resetFilters = [];

      if(state[action.payload.table].keepFilters.length) {

        resetFilters = state[action.payload.table].keepFilters.map((excludedFilter) => {

          const filterIndex = state[action.payload.table].filters.findIndex((filter => filter.name === excludedFilter));

          return state[action.payload.table].filters[filterIndex];
        });
      }

      // TODO check total pages

      return {
        ...state,
        [action.payload.table]: {
          ...state[action.payload.table],
          data: [],
          filters: resetFilters,
          orders: [],
          pageIndex: 1,
          pageSize: 50,
          empty: false
        }
      };
    case "EMPTY":

      return {
        ...state,
        [action.payload.table]: {
          ...state[action.payload.table],
          empty: true
        }
      };
    default:
      return state;
  }
};

export const TableProvider = ({ children }) => {

  const [tableState, tableDispatch] = React.useReducer(reducer, {});
  const {state} = React.useContext(AuthContext);

  const reset = (table) => {
    tableDispatch({
      type: 'RESET',
      payload: {
        table: table
      }
    });
  }

  const buildQueryString = (orders, filters, pageIndex, pageSize) => {

    const ordersQueryString = orders.map((order) => {
      return 'order=' + order.name + ',' + order.verse
    });

    const filtersQueryString = filters.map((filter) => {
      return 'filter=' + filter.name + ',' + filter.operator + ',' + filter.value
    });

    const pagingQueryString = ['page_index=' + pageIndex, 'page_size=' + pageSize];

    const queryString = ordersQueryString.concat(filtersQueryString).concat(pagingQueryString);

    return queryString.join('&');
  }

  const filterBy = (table, filters) => {
    tableDispatch({
      type: 'FILTER',
      payload: {
        table: table,
        filters: filters
      }
    });
  }

  const paginate = (table, pageIndex/*, pageSize*/) => {

    tableDispatch({
      type: 'PAGINATE',
      payload: {
        table: table,
        pageIndex: pageIndex, 
        //pageSize: pageSize
      }
    });
  }

  const orderBy = (table, columnName) => {
    const column = (tableState[table].orders.length) ? tableState[table].orders.find(value => value.name === columnName) : null;

    if(!column || typeof column === 'undefined') {

      tableDispatch({
        type: 'ORDER',
        payload: {
          table: table,
          order: {
            name: columnName,
            verse: 'asc'
          }
        }
      });
    } else {

      const verse = (column.verse === 'asc') ? 'desc' : 'asc';

      tableDispatch({
        type: 'VERSE',
        payload: {
          table: table,
          order: {
            name: columnName,
            verse: verse
          }
        }
      });      
    }
  }

  const getTableData = async (what, table, cancelToken) => {

    const token = state.user.token;

    const opts = {
      headers: {
          'X-Elaisian-Authorization': token
      },
      cancelToken: cancelToken
    };
    
    let query = '';

    if(tableState && tableState[table]) {
      query = '?' + buildQueryString(tableState[table].orders, tableState[table].filters, tableState[table].pageIndex, tableState[table].pageSize);
    }

    if(!tableState[table].empty){
      const response = await API.get(what + query, opts);

      if (response.data.status === 'ok') {

        let data = Object.entries(response.data.result).filter((value) => {
          return Array.isArray(value[1]);
        });

        if(data[0][1].length) {
          tableDispatch({
            type: 'DATA',
            payload: {  
              table: table,
              data: data[0][1],
              total: response.data.result.total
            }
          });
        } else {
          tableDispatch({
            type: 'EMPTY',
            payload: {  
              table: table
            }
          });
        }
      } else {
         console.log("ERROR: ", response.error_code);
      }
    }
  }

  return (
    <TableContext.Provider
      value={{
        tableDispatch,
        getTableData,
        tableState,
        filterBy,
        orderBy,
        paginate,
        reset
      }}
    >
      {children}
    </TableContext.Provider>
  )
};