import React from 'react';
import Col from 'react-bootstrap/Col';
import { AuthContext } from '../../context/AuthProvider';
import { ConfigContext } from '../../context/ConfigProvider';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const ManageDevice = (props) => {

  const deviceStatus = [
    {
        name: 'NEW',
        value: 'new'
    },{
        name: 'INSTALLED',
        value: 'installed'
    },{
        name: 'RUNNING',
        value: 'running'
    },{
        name: 'STOPPED',
        value: 'stopped'
    },{
        name: 'SLEEPING',
        value: 'sleeping'
    },{
        name: 'UNINSTALLED',
        value: 'uninstalled'
    },{
        name: 'ERROR',
        value: 'error'
    }
  ];

  const formParamsInitialState = {
    device_code: '',
    name: '',
    device_type: '',
    clone_device_id: '',
    status: '',
    enabled: false
  };

  const selectDeviceInitialState = {
    isLoading: false,
    options: [],
    selected: [{
      'id': '',
      'device_code': '',
    }]
  };

  const errorAlertInitalState = {
    show: false,
    message: ''
  };

  const {state} = React.useContext(AuthContext);
  const {configState} = React.useContext(ConfigContext);
  const [validated, setValidated] = React.useState(false);
  const [successAlert, setSuccessAlertState] = React.useState(false);
  const [formParams, setFormParams] = React.useState(formParamsInitialState);
  const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
  const [selectDeviceState, setSelectDeviceState] = React.useState(selectDeviceInitialState);
  const {tableDispatch} = React.useContext(TableContext);
  const {modalDispatch} = React.useContext(ModalContext);
  const [loading, setLoading] = React.useState(false);

  const handleDeviceSearch = async (query) => {

    setSelectDeviceState({ 
      ...selectDeviceState,
      isLoading: true 
    });

    try {
      const opts = {
        headers: {
            'X-Elaisian-Authorization': state.user.token
        },
        params: {
            filter:  'device_code,starts_with,' + query,
            'search_type': 'ac_clone'
        }
      };
      const response = await API.get('operations/devices', opts);
      if (response.data.status === 'ok') {
        setSelectDeviceState({ 
          ...selectDeviceState,
          isLoading: false,
          options: response.data.result.devices
        });
      } else {
        setSelectDeviceState({ 
          ...selectDeviceState,
          isLoading: false 
        });
        console.log("ERROR: ", response.error_code);
      }
    } catch (e) {
      setSelectDeviceState({ 
        ...selectDeviceState,
        isLoading: false 
      });
      console.log(e);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    setLoading(true);

    if (form.checkValidity()) {

      try {
          const token = state.user.token;
          const opts = {
              headers: {
                  'X-Elaisian-Authorization': token
              }
          };

          let response;

          if(props.action === 'edit') {
              response = await API.put('operations/devices/' + props.id, formParams, opts);
          } else {
              response = await API.post('operations/devices/', formParams, opts);
          }
          if (response.data.status === 'ok') {
              setSuccessAlertState(true);

              setTimeout(function() {
                modalDispatch({
                    type: 'CLOSE'
                });
                tableDispatch({
                  type: 'DATA',
                  payload: {  
                    table: 'devicesDetails-' + props.id,
                    data: []
                  }
                });
                tableDispatch({
                    type: 'DATA',
                    payload: {
                        'table': 'devices',
                        'data': []
                    }
                });
              }, 1500)
          } else {
              setErrorAlertState({
                show: true,
                message: response.error_message
              });
              setLoading(false);
              console.log("ERROR: ", response.error_code);
          }
      } catch (e) {
          setErrorAlertState({
            show: true,
            message: e.response.data.error_message
          });
          setLoading(false);
      }
    } else {
      setValidated(true);
      setLoading(false);
    }
  }

  const getDeviceData = async (cancelToken) => {
    try {
        const opts = {
            headers: {
                'X-Elaisian-Authorization': state.user.token
            },
            cancelToken: cancelToken
        };
        const response = await API.get('operations/devices/' + props.id, opts);
        if (response.data.status === 'ok') {
            setFormParams(response.data.result);
            if(response.data.result.clone) {
              setSelectDeviceState({ 
                ...selectDeviceState,
                options: [response.data.result.clone],
                selected: [response.data.result.clone],
              });
            }
        } else {
            console.log("ERROR: ", response.error_code);
        }
    } catch (e) {
        console.log(e);
    }
  };

  /*const DeviceClone = () => {
    return(
      formParams.device_type === 'clone'
      ? <Form.Group as={Col}>
        <Form.Label htmlFor="new-clone_device_id"><b>Select Device</b></Form.Label>
        <AsyncTypeahead
          {...selectDeviceState}
          id="add-user"
          labelKey="last_name"
          minLength={2}
          onSearch={handleUserSearch}
          onChange={(selected) => {
            setSelectDeviceState({ 
              ...selectDeviceState,
              selected: selected
            });
            setFormParams({ 
              ...formParams,
              user_id: selected[0] ? selected[0].id : ''
            });
          }}
          renderMenuItemChildren={(option, props) => (
            <div key={option.id}>
              { option.last_name + ' ' + option.first_name }
            </div>
          )}
          options={selectDeviceState.options}
          inputProps={{ required: true }}
          selected={selectDeviceState.selected}
        />
      </Form.Group>
      : <Form.Group as={Col}></Form.Group>
    );
  }*/

  React.useEffect(() => {
    if(props.action === 'edit') {

        const source = CancelToken.source();

        getDeviceData(source.token);

        return () => {
            source.cancel();
        };
    }
  }, []);

  return (
    !successAlert
    ? <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
        <Form.Row>
          <Form.Group as={Col} controlId="new-device_code">
            <Form.Label><b>Code</b></Form.Label>
            <Form.Control type="text" value={formParams.device_code || ''} onChange={(e) => {
              setFormParams({
                ...formParams,
                device_code: e.currentTarget.value
              });
            }} required/>
          </Form.Group>

          <Form.Group as={Col} controlId="new-name">
            <Form.Label><b>Name</b></Form.Label>
            <Form.Control type="text" value={formParams.name || ''} onChange={(e) => {
              setFormParams({
                ...formParams,
                name: e.currentTarget.value
              });
            }} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} xs="6">
            <Form.Label htmlFor="device_type"><b>Device Type</b></Form.Label>
            <Form.Control id="device_type" as="select" value={formParams.device_type || ''} custom onChange={(e) => {
              setFormParams({
                ...formParams,
                device_type: e.currentTarget.value
              });
            }} required>
              <option label=" "></option>
              {configState.device_type.map((type, i) => (
                <option key={i} value={type.id}>{type.name}</option>
              ))}
           </Form.Control>
          </Form.Group>
          <Form.Group as={Col} style={formParams.device_type === 'clone' ? {display: 'block'} : {display: 'none'}}>
            <Form.Label htmlFor="new-clone_device_id"><b>Select Device</b></Form.Label>
            <AsyncTypeahead
              {...selectDeviceState}
              id="new-device_code"
              labelKey="device_code"
              minLength={2}
              onSearch={handleDeviceSearch}
              onChange={(selected) => {
                setSelectDeviceState({ 
                  ...selectDeviceState,
                  selected: selected
                });
                setFormParams({ 
                  ...formParams,
                  clone_device_id: selected[0] ? selected[0].id : ''
                });
              }}
              renderMenuItemChildren={(option, props) => (
                <div key={option.id}>
                  { option.device_code }
                </div>
              )}
              options={selectDeviceState.options}
              selected={selectDeviceState.selected}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs="6" controlId="new-status">
            <Form.Label><b>Status</b></Form.Label>

            <Form.Control as="select" value={formParams.status || ''} custom onChange={(e) => {
              setFormParams({
                ...formParams,
                status: e.currentTarget.value
              });
            }}>
              <option label=" "></option>
              {deviceStatus.map((type, i) => (
                <option key={i} value={type.value}>{type.name}</option>
              ))}
           </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Check
              custom
              type="switch"
              label="Enabled"
              id="new-enabled"
              checked={formParams.enabled}
              onChange={(e) => {
                setFormParams({
                  ...formParams,
                  enabled: e.target.checked
                });
              }}
            />
          </Form.Group>
        </Form.Row>
        <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
           ...errorAlertState,
           show: false
        })} dismissible>
          <Alert.Heading>Ops! C'è stato un errore!</Alert.Heading>
          <p>
            { errorAlertState.message }
          </p>
        </Alert>
        <Form.Row className="mt-3">
            <Col>
                <Button variant="primary" type="submit" disabled={loading}>
                  <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                  INVIA
                </Button>
            </Col>
        </Form.Row>
      </Form>
    : <Alert variant="success">
        <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
      </Alert>
  );
}

export default ManageDevice;