import React from 'react';
import VARS from '../../utils/Vars';
import Script from 'react-load-script';
import { useParams } from 'react-router';
import ManageTerrain from './ManageTerrain';
import Modal from '../../common/modal/Modal';
import TerrainDevices from './TerrainDevices';
import TerrainPolygons from './TerrainPolygons';
import { useHistory } from 'react-router-dom';
import Table from '../../components/table/Table';
import Details from '../../components/details/Details';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AuthContext } from '../../context/AuthProvider';
import Alert from "react-bootstrap/Alert";

const ImportCSV = (props) => {
    const errorAlertInitalState = {
        show: false,
        message: ''
    };
    const [successAlert, setSuccessAlertState] = React.useState(false);
    const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
    const {modalDispatch} = React.useContext(ModalContext);
    const [loading, setLoading] = React.useState(false);
    const {state} = React.useContext(AuthContext);
    const fileCSV = React.createRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = state.user.token;
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': token
                }
            };
            const formData = new FormData();
            formData.append('file_csv', fileCSV.current.files[0]);
            const response = await API.post('operations/import/sat-report/csv', formData, opts);
            if (response.data.status === 'ok') {
                setLoading(false);
                setSuccessAlertState(true);
                setTimeout(function() {
                    modalDispatch({
                        type: 'CLOSE'
                    });
                }, 1500)
            } else {
                let message = response.error_message + ': <br>';
                response.details.forEach(function(detail){
                    message += '-' + detail + ';<br>';
                });
                setErrorAlertState({
                    show: true,
                    message: message
                });
                setLoading(false);
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            let message = e.response.data.error_message + ': <br>';
            e.response.data.details.forEach(function(detail){
                message += '-' + detail + ';<br>';
            });
            setErrorAlertState({
                show: true,
                message: message
            });
            setLoading(false);
            console.log(e);
        }
    }

    return(
        !successAlert
            ?
            <form className="m-1 mb-0" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group col-auto">
                        <input type="file" className="form-control" id="validatedCSVFile" required ref={fileCSV}/>
                    </div>
                </div>
                <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
                    ...errorAlertState,
                    show: false
                })} dismissible>
                    <Alert.Heading>Ops!</Alert.Heading>
                    <div dangerouslySetInnerHTML={{ __html: errorAlertState.message }} />
                </Alert>
                <div className="form-row">
                    <div className="form-group col-auto">
                        <button type="submit" className="btn btn-primary mr-2" disabled={loading}>
                            <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                            Import CSV
                        </button>
                    </div>
                </div>
            </form>
            : <Alert variant="success">
                <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
            </Alert>
    );
}

const Terrains = () => {

    const {modalDispatch} = React.useContext(ModalContext);
    const {state, canManage} = React.useContext(AuthContext);
    const {tableState, getTableData, tableDispatch} = React.useContext(TableContext);
    const history = useHistory();
    const {id} = useParams();

    const columns = [
        {
            id: 'terrain_name',
            name: 'Terrain Name',
            type: 'string'
        },{
            id: 'bio_cultivation',
            name: 'BIO',
            type: 'boolean'
        },{
            id: 'terrain_location',
            name: 'Terrain Location',
            type: 'string'
        },{
            id: 'created',
            name: 'Created',
            type: 'timestamp'
        },{
            id: 'id',
            name: 'ID DB',
            type: 'string'
        }
    ];

    const customColumns = [
        {
            id: 'ad_num_region',
            name: 'Region',
            type: 'string'
        },{
            id: 'ad_num_province',
            name: 'Province',
            type: 'string'
        },{
            id: 'ad_num_commune',
            name: 'Commune',
            type: 'string'
        }
    ];

    const detailsComponents = [
        {
            'name': 'Devices',
            'load': <TerrainDevices />
        },
        {
            'name': 'Polygons',
            'load': <TerrainPolygons />
        }
    ];

    const actions = [
        {
            name: 'Details',
            call: (terrain) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Terrain Details',
                        size: 'xl',
                        show: true,
                        load: <Details what='operations/terrains' table='terrains' id={terrain.id} components={detailsComponents} customColumns={customColumns} />
                    }
                })
            }
        },
        {
            name: 'ALERTS',
            call: (terrain) => {
                if(terrain.enabled) {
                    history.push('/programs/user/' + terrain.user_id + '/terrain/' + terrain.id);
                } else {
                    alert('Terrain is disabled!');
                }
            }
        }
    ];
    if (canManage('terrains', 'write')) {
        actions.push(
            {
                name: 'EDIT',
                call: (terrain) => {
                    modalDispatch({
                        type: 'OPEN',
                        payload: {
                            title: 'Edit Terrain',
                            size: 'lg',
                            show: true,
                            load: <ManageTerrain action="edit" id={terrain.id} />
                        }
                    })
                }
            }
        );
    }

    React.useEffect(() => {

        if(id) {
            modalDispatch({
                type: 'OPEN',
                payload: {
                    title: 'Terrain Details',
                    size: 'xl',
                    show: true,
                    load: <Details what='operations/terrains' table='terrains' id={id} components={detailsComponents} />
                }
            });
        }

        const source = CancelToken.source();

        if(!tableState || !tableState.terrains) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: 'terrains',
                columns: columns,
                data: [],
                filters: [],
                orders: []
              }
            });
        }

        if(tableState && tableState.terrains && !tableState.terrains.data.length) {
            getTableData('operations/terrains', 'terrains', source.token);
        }

        return () => {
            source.cancel();
        };
    }, [tableState.terrains])

    return (
        <div className="container-fluid pb-3">
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {
                                    canManage('terrains', 'write') ?
                                    <div className="col m-1">
                                        <button className="btn btn-outline-success" onClick={() =>{
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'New Terrain',
                                                    size: 'lg',
                                                    show: true,
                                                    load: <ManageTerrain />
                                                }
                                            })
                                        }}>New Terrain</button> &nbsp;
                                        <button className="btn btn-outline-info" onClick={() => {
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'Import Sat Report CSV',
                                                    size: 'md',
                                                    show: true,
                                                    load: <ImportCSV />
                                                }
                                            })
                                        }}>Import Sat Report CSV</button>
                                    </div> : <React.Fragment />
                                }
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Table table="terrains" actions={actions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
            <Script url={"https://maps.googleapis.com/maps/api/js?key=" + VARS.GOOGLE.API_KEY + "&libraries=places"}/>    
        </div>
    )
}

export default Terrains;