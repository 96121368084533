import React, { Component } from 'react';
import { useCookies } from 'react-cookie';
import { AuthContext } from "../../context/AuthProvider";
import { ConfigContext } from '../../context/ConfigProvider';
import API from '../../utils/AjaxUtils';

const Login = () => {

    const { dispatch } = React.useContext(AuthContext);
    const [cookies, setCookie] = useCookies(['user']);

    const handleChange = (event) => {
        
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const target = event.target;
        
        const data = {
            username: target.username.value,
            password: target.password.value
        };

        const login = await API.post('login', data);

        if (login.data.status === 'ok') {

            const opts = {
                headers: {
                    'X-Elaisian-Authorization': login.data.result.token
                }
            };

            const me = await API.get('me', opts);

            if (me.data.status === 'ok') {

                setCookie('user', JSON.stringify({
                    token: login.data.result.token,
                    name: me.data.result.displayName,
                    roles: me.data.result.roles
                }), { path: '/' });

                dispatch({
                    type: "LOGIN",
                    payload: {
                        user: {
                            token: login.data.result.token,
                            name: me.data.result.displayName,
                            roles: me.data.result.roles
                        }
                    }
                });
            } else {
                console.log("ERROR: ", me.error_code);
            }
        } else {
            console.log("ERROR: ", login.error_code);

        }
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-4">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit} className="mt-3">
                                <div className="form-group">
                                    <label>Username:</label>
                                    <input className="form-control" type="text" name="username" id="username" onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label>Password:</label>
                                    <input className="form-control" type="password" name="password" id="password" onChange={handleChange}/>
                                </div>
                                <button type="submit" className="btn btn-primary">Login</button>
                            </form>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    )    
}

export default Login;