import React from 'react';
import { useHistory } from 'react-router-dom';
import { CancelToken } from '../../utils/AjaxUtils';
import Table from '../../components/table/Table';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';

const UserTerrains = (props) => {

    const {tableState, getTableData, tableDispatch} = React.useContext(TableContext);
    const {modalDispatch} = React.useContext(ModalContext);
    const history = useHistory();

    const userTerrainsColumns = [
        {
            id: 'id',
            name: 'ID DB',
            type: 'string'
        },{
            id: 'terrain_name',
            name: 'Terrain Name',
            type: 'string'
        }/*,{
            id: 'bio_cultivation',
            name: 'BIO',
            type: 'boolean'
        }*/,{
            id: 'terrain_location',
            name: 'Terrain Location',
            type: 'string'
        }
    ];

    const actions = [
        {
            name: 'Details',
            call: (terrain) => {
                modalDispatch({
                    type: 'CLOSE'
                });
                history.push('/terrains/' + terrain.id);
            }
        }
    ];

    React.useEffect(() => {

        const source = CancelToken.source();

        if(!tableState['userTerrains-' + props.element.id]) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: 'userTerrains-' + props.element.id,
                columns: userTerrainsColumns,
                data: [],
                filters: [
                    {
                        name: 'user_id',
                        operator: 'equals',
                        value: props.element.id
                    }
                ],
                orders: [],
                keepFilters: ['user_id'],
                hidePagination: true
              }
            });
        }

        if(tableState && tableState['userTerrains-' + props.element.id] && !tableState['userTerrains-' + props.element.id].data.length) {
            getTableData('operations/terrains', 'userTerrains-' + props.element.id, source.token);
        }

        return () => {
            source.cancel();
        };

    }, [tableState['userTerrains-' + props.element.id]])
    return (
        <div className="row">
            <div className="col-12">
                <Table table={'userTerrains-' + props.element.id} actions={actions} />
            </div>
        </div>
    );
}

export default UserTerrains;