import React from 'react';
import VARS from '../../utils/Vars';
import { useParams } from 'react-router';
import ManageActivity from './ManageActivity';
import Modal from '../../common/modal/Modal';
import { useHistory } from 'react-router-dom';
import Table from '../../components/table/Table';
import Details from '../../components/details/Details';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AuthContext } from '../../context/AuthProvider';
import { ConfigContext } from '../../context/ConfigProvider';

const Activities = () => {

    const {modalDispatch} = React.useContext(ModalContext);
    const {configState} = React.useContext(ConfigContext);
    const {state, canManage} = React.useContext(AuthContext);
    const {tableState, getTableData, tableDispatch} = React.useContext(TableContext);
    const history = useHistory();
    const {id} = useParams();

    const columns = [
        {
            id: 'enabled',
            name: 'Enabled',
            type: 'boolean'
        },{
            id: 'device_id',
            name: 'Device',
            type: 'autocomplete',
            url: '/operations/devices',
            paramName: 'device_code',
            labelKey: 'device_code',
            displayName: 'device_code',
            result: 'devices',
            showInTable: 'device_code'
        },{
            id: 'user_id',
            name: 'Username',
            type: 'autocomplete',
            url: '/operations/users',
            paramName: 'email',
            labelKey: 'email',
            displayName: 'email',
            result: 'users',
            showInTable: 'username'
        },{
            id: 'terrain_id',
            name: 'Terrain name',
            type: 'autocomplete',
            url: '/operations/terrains',
            paramName: 'terrain_name',
            labelKey: 'terrain_name',
            displayName: 'terrain_name',
            result: 'terrains',
            showInTable: 'terrain_name'
        },{
            id: 'phyto_product_name',
            name: 'Product name',
            type: 'string'
        },{
            id: 'crop_type',
            name: 'Crop Type',
            type: 'select',
            options: configState && configState.crop_type ? configState.crop_type : [],
        },{
            id: 'activity_type',
            name: 'Activity Type',
            type: 'select',
            //child: 'activity_sub_type',
            options: configState && configState.activities && configState.activities.activity_type ? configState.activities.activity_type : [],
        }/*,{
            id: 'activity_sub_type',
            name: 'Activity Sub Type',
            type: 'sub-select',
            selects: configState && configState.activities.activity_type ? configState.activities.activity_type.map((o) => {return {id: o.id, options: o.activity_sub_type}}) : [],
        }*/,{
            id: 'message',
            name: 'Message',
            type: 'string'
        },{
            id: 'created',
            name: 'Created',
            type: 'timestamp'
        },{
            id: 'date_start',
            name: 'Date Start',
            type: 'timestamp'
        },{
            id: 'id',
            name: 'ID DB',
            type: 'string'
        }
    ];

    const detailCustomColumns = [
        {
            id: 'activity_sub_type',
            name: 'Activity Sub Type',
            type: 'sub-select',
            selects: configState && configState.activities && configState.activities.activity_type ? configState.activities.activity_type.map((o) => {return {id: o.id, options: o.activity_sub_type}}) : [],
        }
    ];

    const detailsComponents = [];

    const actions = canManage('activities', 'write') ? [
        {
            name: 'Details',
            call: (terrain) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Activities Details',
                        size: 'xl',
                        show: true,
                        load: <Details what='operations/activities' table='activities' id={terrain.id} components={detailsComponents} customColumns={detailCustomColumns} />
                    }
                })
            }
        },{
            name: 'EDIT',
            call: (activity) => {
                modalDispatch({
                    type: 'OPEN',
                    payload: {
                        title: 'Edit Details',
                        size: 'lg',
                        show: true,
                        load: <ManageActivity action="edit" id={activity.id} />
                    }
                })
            }
        },{
            name: 'DISABLE',
            className: 'danger',
            call: async (activity) => {
                var confirm = window.confirm("Are you sure?");
                if (confirm) {
                    try {
                        const opts = {
                            headers: {
                                'X-Elaisian-Authorization': state.user.token
                            }
                        };
                        const response = await API.delete('operations/activities/'+ activity.id, opts);
                        if (response.data.status === 'ok') {
                            tableDispatch({
                              type: 'DATA',
                              payload: {  
                                table: 'activities',
                                data: []
                              }
                            });
                        } else {
                            console.log("ERROR: ", response.error_code);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        }
    ] : [];

    React.useEffect(() => {

        const source = CancelToken.source();

        if(!tableState || !tableState.activities) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: 'activities',
                columns: columns,
                data: [],
                filters: [],
                orders: []
              }
            });
        }

        if(tableState && tableState.activities && !tableState.activities.data.length) {
            getTableData('operations/activities', 'activities', source.token);
        }

        return () => {
            source.cancel();
        };
    }, [tableState.activities])

    return (
        <div className="container-fluid pb-3">
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col m-1">
                                    {
                                        canManage('activities', 'write') &&
                                        <button className="btn btn-outline-success" onClick={() =>{
                                            modalDispatch({
                                                type: 'OPEN',
                                                payload: {
                                                    title: 'New Activity',
                                                    size: 'lg',
                                                    show: true,
                                                    load: <ManageActivity />
                                                }
                                            })
                                        }}>New Activity</button>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Table table="activities" actions={actions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal /> 
        </div>
    )
}

export default Activities;