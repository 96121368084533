import React from 'react';
import Col from 'react-bootstrap/Col';
import { AuthContext } from '../../context/AuthProvider';
import { ConfigContext } from '../../context/ConfigProvider';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import { MdVerifiedUser } from 'react-icons/md';
import { MdError } from 'react-icons/md';
import moment from 'moment';

const ManageActivity = (props) => {

  const formParamsInitialState = {
    user_id: '',
    terrain_id: '',
    phyto_product_id: null,
    phyto_product_name: '',
    phyto_product_type: '',
    phyto_product_quantity: '',
    activity_type: '',
    activity_sub_type: [],
    crop_type: '',
    date_start: null,
    message: ''
  };

  const selectProductInitialState = {
    isLoading: false,
    options: [],
    selected: [{
      'id': '',
      'name': '',
    }]
  };

  const selectDeviceInitialState = {
    isLoading: false,
    options: [],
    selected: [{
      'id': '',
      'device_code': '',
    }]
  };

  const selectUserInitialState = {
    isLoading: false,
    options: [],
    selected: [{
      'id': '',
      'email': '',
    }]
  };

  const selectTerrainInitialState = {
    isLoading: false,
    options: [],
    selected: [{
      'id': '',
      'terrain_name': '',
    }]
  };

  const errorAlertInitalState = {
    show: false,
    message: ''
  };

  const {state} = React.useContext(AuthContext);
  const {configState} = React.useContext(ConfigContext);
  const [validated, setValidated] = React.useState(false);
  const [successAlert, setSuccessAlertState] = React.useState(false);
  const [formParams, setFormParams] = React.useState(formParamsInitialState);
  const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
  const [selectDeviceState, setSelectDeviceState] = React.useState(selectDeviceInitialState);
  const [selectProductState, setSelectProductState] = React.useState(selectProductInitialState);
  const [selectUserState, setSelectUserState] = React.useState(selectUserInitialState);
  const [selectTerrainState, setSelectTerrainState] = React.useState(selectTerrainInitialState);
  const [multiSelections, setMultiSelections] = React.useState([]);
  const {tableDispatch} = React.useContext(TableContext);
  const {modalDispatch} = React.useContext(ModalContext);
  const [loading, setLoading] = React.useState(false);

  const handleDeviceSearch = async (query) => {

    setSelectDeviceState({ 
      ...selectDeviceState,
      isLoading: true 
    });

    try {
      const opts = {
        headers: {
            'X-Elaisian-Authorization': state.user.token
        },
        params: {
            filter:  'device_code,starts_with,' + query,
            'search_type': 'ac'
        }
      };
      const response = await API.get('operations/devices', opts);
      if (response.data.status === 'ok') {
        setSelectDeviceState({ 
          ...selectDeviceState,
          isLoading: false,
          options: response.data.result.devices
        });
      } else {
        setSelectDeviceState({ 
          ...selectDeviceState,
          isLoading: false 
        });
        console.log("ERROR: ", response.error_code);
      }
    } catch (e) {
      setSelectDeviceState({ 
        ...selectDeviceState,
        isLoading: false 
      });
      console.log(e);
    }
  }

  const handleUserSearch = async (query) => {

    setSelectUserState({ 
      ...selectUserState,
      isLoading: true 
    });

    try {
      const opts = {
        headers: {
            'X-Elaisian-Authorization': state.user.token
        },
        params: {
            filter:  'email,starts_with,' + query,
            'search_type': 'ac'
        }
      };
      const response = await API.get('operations/users', opts);
      if (response.data.status === 'ok') {
        setSelectUserState({ 
          ...selectUserState,
          isLoading: false,
          options: response.data.result.users
        });
      } else {
        setSelectUserState({ 
          ...selectUserState,
          isLoading: false 
        });
        console.log("ERROR: ", response.error_code);
      }
    } catch (e) {
      setSelectUserState({ 
        ...selectUserState,
        isLoading: false 
      });
      console.log(e);
    }
  }

  const handleTerrainSearch = async (query) => {

    setSelectTerrainState({ 
      ...setSelectTerrainState,
      isLoading: true 
    });

    try {
      const opts = {
        headers: {
          'X-Elaisian-Authorization': state.user.token
        },
        params: {
          filter:  'terrain_name,starts_with,' + query,
          'search_type': 'ac'
        }
      };
      const response = await API.get('operations/terrains', opts);
      if (response.data.status === 'ok') {
        setSelectTerrainState({ 
          ...setSelectTerrainState,
          isLoading: false,
          options: response.data.result.terrains
        });
      } else {
        setSelectTerrainState({ 
          ...setSelectTerrainState,
          isLoading: false 
        });
        console.log("ERROR: ", response.error_code);
      }
    } catch (e) {
      setSelectTerrainState({ 
        ...setSelectTerrainState,
        isLoading: false 
      });
      console.log(e);
    }
  }

  const handleProductSearch = async (query) => {

    setSelectProductState({ 
      ...setSelectProductState,
      isLoading: true 
    });

    try {
      const opts = {
        headers: {
          'X-Elaisian-Authorization': state.user.token
        },
        params: {
          filter:  'name,starts_with,' + query,
          'search_type': 'ac'
        }
      };
      const response = await API.get('operations/phyto-products', opts);
      if (response.data.status === 'ok') {
        setSelectProductState({ 
          ...setSelectProductState,
          isLoading: false,
          options: response.data.result.products
        });
      } else {
        setSelectProductState({ 
          ...setSelectProductState,
          isLoading: false 
        });
        console.log("ERROR: ", response.error_code);
      }
    } catch (e) {
      setSelectProductState({ 
        ...setSelectProductState,
        isLoading: false 
      });
      console.log(e);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    setLoading(true);

    if (form.checkValidity()) {

      try {
          const token = state.user.token;
          const opts = {
              headers: {
                  'X-Elaisian-Authorization': token
              }
          };

          let response;

          if(props.action === 'edit') {
              response = await API.put('operations/activities/' + props.id, formParams, opts);
          } else {
              response = await API.post('operations/activities', formParams, opts);
          }
          if (response.data.status === 'ok') {
              setSuccessAlertState(true);

              setTimeout(function() {
                modalDispatch({
                    type: 'CLOSE'
                });
                tableDispatch({
                  type: 'DATA',
                  payload: {  
                    table: 'activities',
                    data: []
                  }
                });
              }, 1500)
          } else {
              setErrorAlertState({
                show: true,
                message: response.error_message
              });
              setLoading(false);
              console.log("ERROR: ", response.error_code);
          }
      } catch (e) {
          setErrorAlertState({
            show: true,
            message: e.response.data.error_message
          });
          setLoading(false);
      }
    } else {
      setValidated(true);
      setLoading(false);
    }
  }

  const getActivityData = async (cancelToken) => {
    try {
        const opts = {
            headers: {
                'X-Elaisian-Authorization': state.user.token
            },
            cancelToken: cancelToken
        };
        const response = await API.get('operations/activities/' + props.id, opts);
        if (response.data.status === 'ok') {
            setFormParams(response.data.result);
            if(response.data.result.activity_type && response.data.result.activity_sub_type.length) {
              setMultiSelections(response.data.result.activity_sub_type.map((activity_sub_type) => configState.activities.activity_type.find((element) => element.id === response.data.result.activity_type).activity_sub_type.find((element) => element.id === activity_sub_type)));
            }
            if(response.data.result.user_id) {
              setSelectUserState({ 
                ...selectUserState,
                selected: [{id: response.data.result.user_id, email: response.data.result.user.email}],
              });
            }
            if(response.data.result.terrain_id) {
              setSelectTerrainState({ 
                ...selectTerrainState,
                selected: [{id: response.data.result.terrain_id, terrain_name: response.data.result.terrain.terrain_name}],
              });
            }
            if(response.data.result.device_id) {
              setSelectDeviceState({ 
                ...selectDeviceState,
                selected: [{id: response.data.result.device_id, device_code: response.data.result.device.device_code}],
              });
            }
            if(response.data.result.phyto_product_id || response.data.result.phyto_product_name) {
              setSelectProductState({ 
                ...selectProductState,
                selected: [{id: response.data.result.phyto_product_id || '', name: response.data.result.phyto_product_name}],
              });
            }
        } else {
            console.log("ERROR: ", response.error_code);
        }
    } catch (e) {
        console.log(e);
    }
  };


  const getProductType = (id) => {
    return configState.activities.phyto_product_type.find((element) => element.id === formParams.phyto_product_type);
  }

  React.useEffect(() => {
    if(props.action === 'edit') {

        const source = CancelToken.source();

        getActivityData(source.token);

        return () => {
            source.cancel();
        };
    }
  }, []);

  return (
    !successAlert
    ? <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
        <Form.Row>
          <Form.Group as={Col} xs="4">
            <Form.Label htmlFor="new-user_id"><b>Select User</b></Form.Label>
            <AsyncTypeahead
              {...selectUserState}
              id="new-user_id"
              labelKey="email"
              minLength={2}
              onSearch={handleUserSearch}
              onChange={(selected) => {
                setSelectUserState({ 
                  ...selectUserState,
                  selected: selected
                });
                setFormParams({ 
                  ...formParams,
                  user_id: selected[0] ? selected[0].id : ''
                });
              }}
              renderMenuItemChildren={(option, props) => (
                <div key={option.email}>
                  { option.email }
                </div>
              )}
              options={selectUserState.options}
              selected={selectUserState.selected}
            />
          </Form.Group>
          <Form.Group as={Col} xs="4">
            <Form.Label htmlFor="new-terrain_id"><b>Select Terrain</b></Form.Label>
            <AsyncTypeahead
              {...selectTerrainState}
              id="new-terrain_id"
              labelKey="terrain_name"
              minLength={2}
              onSearch={handleTerrainSearch}
              onChange={(selected) => {
                setSelectTerrainState({ 
                  ...selectTerrainState,
                  selected: selected
                });
                setFormParams({ 
                  ...formParams,
                  terrain_id: selected[0] ? selected[0].id : ''
                });
              }}
              renderMenuItemChildren={(option, props) => (
                <div key={option.terrain_name}>
                  { option.terrain_name }
                </div>
              )}
              options={selectTerrainState.options}
              selected={selectTerrainState.selected}
            />
          </Form.Group>
          <Form.Group as={Col} xs="4">
            <Form.Label htmlFor="new-device_id"><b>Select Device</b></Form.Label>
            <AsyncTypeahead
              {...selectDeviceState}
              id="new-device_id"
              labelKey="device_code"
              minLength={2}
              onSearch={handleDeviceSearch}
              onChange={(selected) => {
                setSelectDeviceState({ 
                  ...selectDeviceState,
                  selected: selected
                });
                setFormParams({ 
                  ...formParams,
                  device_id: selected[0] ? selected[0].id : ''
                });
              }}
              renderMenuItemChildren={(option, props) => (
                <div key={option.device_code}>
                  { option.device_code }
                </div>
              )}
              options={selectDeviceState.options}
              selected={selectDeviceState.selected}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs="4" controlId="new-crop_type">
            <Form.Label><b>Crop Type</b></Form.Label>
              <Form.Control as="select" value={ formParams.crop_type >= 0 ? formParams.crop_type : ''} custom onChange={(e) => {
                setFormParams({
                  ...formParams,
                  crop_type: e.currentTarget.value,
                  activity_type: '',
                  activity_sub_type: '',
                  phyto_product_type: ''
                });
              }}>
                <option label=" "></option>
                {configState.crop_type.map((type, i) => (
                  <option key={'crop-' + i} value={type.id}>{type.label}</option>
                ))}
             </Form.Control>
          </Form.Group>
          <Form.Group as={Col} xs="4" controlId="new-activity_type">
            <Form.Label><b>Activity Type</b></Form.Label>
            <Form.Control as="select" value={formParams.activity_type || ''} custom onChange={(e) => {
              setFormParams({
                ...formParams,
                activity_type: parseInt(e.currentTarget.value) || '',
                activity_sub_type: '',
                phyto_product_type: '',
                phyto_product_name: ''
              });
            }}>
              <option label=" "></option>
              {configState.activities.activity_type.filter((element) => !element.crop_type).map((type, i) => (
                <option key={'defaulttype-' + i} value={type.id}>{type.label}</option>
              ))}
              {parseInt(formParams.crop_type) && configState.activities.activity_type.filter((element) => element.crop_type == formParams.crop_type).map((type, i) => (
                <option key={'type-' + i} value={type.id}>{type.label}</option>
              ))}
           </Form.Control>
          </Form.Group>
          {
            formParams.activity_type && configState.activities.phyto_product_enabled.find((element) => element === formParams.activity_type) &&
              <Form.Group as={Col} xs="4" controlId="new-phyto_product_type">
                <Form.Label><b>Product Type</b></Form.Label>
                <Form.Control as="select" value={formParams.phyto_product_type || ''} custom onChange={(e) => {
                  setFormParams({
                    ...formParams,
                    phyto_product_type: parseInt(e.currentTarget.value) || '',
                    phyto_product_quantity: '',
                    phyto_product_unit_measure: ''
                  });
                  setMultiSelections([]);
                  setSelectProductState({
                    ...selectProductState,
                    selected: [{
                      'id': '',
                      'name': '',
                    }]
                  });
                }}>
                  <option label=" "></option>
                  {configState.activities.activity_type.find((element) => element.id === formParams.activity_type).phyto_product_type.map((product, i) => 
                    <option key={'product-' + i} value={configState.activities.phyto_product_type.find((element) => element.id === product).id}>{configState.activities.phyto_product_type.find((element) => element.id === product).label}</option>
                  )}
                </Form.Control>
              </Form.Group>
          }
          {
            formParams.phyto_product_type &&
            <Form.Group as={Col} xs="4">
              <Form.Label htmlFor="new-phyto_product_id"><b>Select Product</b></Form.Label>
              <AsyncTypeahead
                {...selectProductState}
                id="new-phyto_product_id"
                labelKey="name"
                minLength={2}
                onSearch={handleProductSearch}
                onInputChange={(string) => {
                  setFormParams({ 
                    ...formParams,
                    phyto_product_name: string
                  });
                }}
                onChange={(selected) => {
                  setSelectProductState({ 
                    ...selectProductState,
                    selected: selected
                  });
                  setFormParams({ 
                    ...formParams,
                    phyto_product_id: selected[0] ? selected[0].id : null,
                    phyto_product_name: selected[0] ? selected[0].name : '',
                  });
                }}
                renderMenuItemChildren={(option, props) => (
                  <div key={option.name}>
                    {
                      configState.activities.phyto_product_status.find((c) => c.id == option.id) &&
                      configState.activities.phyto_product_status.find((c) => c.id == option.id).valid ?
                      <span className="badge badge-success mr-3"><MdVerifiedUser /></span> :
                      <span className="badge badge-warning mr-3"><MdError /></span>
                    }
                    { option.name }
                  </div>
                )}
                options={selectProductState.options}
                selected={selectProductState.selected}
              />
            </Form.Group>
          }
          {
            formParams.phyto_product_type &&
            <Form.Group as={Col} xs="4" controlId="new-phyto_product_quantity">
              <Form.Label><b>Quantity</b></Form.Label>
              <Form.Control type="text" value={formParams.phyto_product_quantity ||  ''} onChange={(e) => {
                  setFormParams({
                    ...formParams,
                    phyto_product_quantity: e.currentTarget.value
                  });
              }} />
            </Form.Group>
         }
         {
            formParams.phyto_product_type &&
            <Form.Group as={Col} xs="4" controlId="new-phyto_product_unit_measure">
              <Form.Label><b>Unit Measure</b></Form.Label>
              <Form.Control as="select" value={ formParams.phyto_product_unit_measure ||  ''} custom onChange={(e) => {
                  setFormParams({
                    ...formParams,
                    phyto_product_unit_measure: parseInt(e.currentTarget.value)
                  });
                }}>
                  <option label=" "></option>
                  {getProductType().phyto_product_unit_measure.map((type, i) => (
                    <option key={'unitmeasure-' + i} value={type.id}>{type.label}</option>
                  ))}
              </Form.Control>
            </Form.Group>
         }
        </Form.Row>
        <Form.Row>
         {
            formParams.phyto_product_type &&
            configState.activities.activity_type.filter((element) => element.id === formParams.activity_type).map((type, i) => (
              <Form.Group key={'new-activity_sub_type' + i} as={Col} xs="12" controlId="new-activity_sub_type">
                <Form.Label><b>Activity Sub Type</b></Form.Label>
                <Typeahead
                  id="new-activity_sub_type"
                  labelKey="label"
                  multiple={type.multivalue}
                  onChange={(selected) => {
                    setMultiSelections(selected);
                    setFormParams({
                      ...formParams,
                      activity_sub_type: selected.map(a => a.id)
                    })
                  }}
                  options={type.activity_sub_type}
                  selected={multiSelections}
                />
              </Form.Group>
            ))
          }
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs="4" controlId="new-date_start">
            <Form.Label><b>Date Start</b></Form.Label>
              <div>
                <DatePicker
                  dateFormat="yyyy-MM-dd HH:mm"
                  showTimeInput
                  selected={formParams.date_start ? new Date(formParams.date_start) : null}
                  onChange={(date) => setFormParams({
                      ...formParams,
                      date_start: date
                  })}
                  locale="en"
                  className="form-control"
                  name="date_start" 
                  id="date_start" 
                  isClearable
                />
            </div>
          </Form.Group>       
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="new-message">
            <Form.Label><b>Message</b></Form.Label>
            <Form.Control type="text" value={formParams.message} onChange={(e) => {
                setFormParams({
                  ...formParams,
                  message: e.currentTarget.value
                });
            }} />
          </Form.Group>
        </Form.Row>
        <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
           ...errorAlertState,
           show: false
        })} dismissible>
          <Alert.Heading>Ops! C'è stato un errore!</Alert.Heading>
          <p>
            { errorAlertState.message }
          </p>
        </Alert>
        <Form.Row className="mt-3">
            <Col>
                <Button variant="primary" type="submit" disabled={loading}>
                  <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                  INVIA
                </Button>
            </Col>
        </Form.Row>
      </Form>
    : <Alert variant="success">
        <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
      </Alert>
  );
}

export default ManageActivity;