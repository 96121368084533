import React from 'react';

export const AuthContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    default:
      return state;
  }
};

export const AuthProvider = ({children}) => {

  const initialState = {
    isAuthenticated: false,
    user: null,
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const canManage = (group, type) => {
    if(!state.user) {
      return false;
    }
    let index = state.user.roles.findIndex(x => x.group === 'manage_' + group);
    if(index === -1) {
      return false;
    }
    if(type) {
      return (state.user.roles[index].type.search(type) !== -1);
    }
    return true;
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        canManage
      }}
    > 
      {children}
    </AuthContext.Provider>
  );
}
