import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { AuthContext } from '../../context/AuthProvider';
import { ConfigContext } from '../../context/ConfigProvider';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';

const ManageLead = (props) => {
    const {configState} = React.useContext(ConfigContext);
    const {state} = React.useContext(AuthContext);
    const channels = configState.leads.channel;
    const cropTypes = configState.leads.crop_type;
    const offerTypes = configState.leads.offer_type;
    const statuses = configState.leads.status;
    
    const formParamsInitialState = {
        address_city: '',
        admin_display_name: '',
        admin_email: '',
        admin_user_id: '',
        admin_username: '',
        channel: '',
        created: '',
        crop_type: '',
        date_start: '',
        description: '',
        email: '',
        enabled: '',
        first_name: '',
        id: '',
        internal_id: '',
        last_name: '',
        offer_type: '',
        phone: '',
        status: '',
        updated: ''
    };

    const errorAlertInitalState = {
        show: false,
        message: ''
    };

    const addUserInitialState = {
        isLoading: false,
        options: [],
        selected: [{
          'id': '',
          'email': '',
          'display_name': '',
          'username': ''
        }]
    };

    const [formParams, setFormParams] = React.useState(formParamsInitialState);
    const [loading, setLoading] = React.useState(false);
    const [validated, setValidated] = React.useState(false);
    const [successAlert, setSuccessAlertState] = React.useState(false);
    const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
    const [addUserState, setAddUserState] = React.useState(addUserInitialState);
    const {tableDispatch} = React.useContext(TableContext);
    const {modalDispatch} = React.useContext(ModalContext);

    const getLeadData = async (cancelToken) => {
        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                cancelToken: cancelToken
            };
            const response = await API.get('sales/leads/' + props.id, opts);
            if (response.data.status === 'ok') {
                setFormParams(response.data.result);
                setAddUserState({
                    ...addUserState,
                    selected: [{
                        'id': response.data.result.admin_user_id,
                        'email': response.data.result.admin_email,
                        'display_name': response.data.result.admin_display_name,
                        'username': response.data.result.admin_username
                    }]
                });
            } else {
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleUserSearch = async (query) => {
        setAddUserState({ 
            ...addUserState,
            isLoading: true 
        });
        try {
            const opts = {
                headers: {
                    'X-Elaisian-Authorization': state.user.token
                },
                params: {
                    filter:  'email,starts_with,' + query,
                    'search_type': 'ac'
                }
            };
            const response = await API.get('/sales/users', opts);
            if (response.data.status === 'ok') {
                setAddUserState({ 
                    ...addUserState,
                    isLoading: false,
                    options: response.data.result.users
                });
            } else {
                setAddUserState({ 
                    ...addUserState,
                    isLoading: false 
                });
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            setAddUserState({ 
                ...addUserState,
                isLoading: false 
            });
            console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        setLoading(true);
        if (form.checkValidity()) {
            try {
                const token = state.user.token;
                const opts = {
                    headers: {
                        'X-Elaisian-Authorization': token
                    }
                };
                let response;
                if(props.action === 'edit') {
                    response = await API.put('sales/leads/' + props.id, formParams, opts);
                } else {
                    response = await API.post('sales/leads/', formParams, opts);
                }
                if (response.data.status === 'ok') {
                    setSuccessAlertState(true);
                    setTimeout(function() {
                        modalDispatch({
                            type: 'CLOSE'
                        });
                        tableDispatch({
                          type: 'DATA',
                          payload: {  
                            table: 'leads',
                            data: []
                          }
                        });
                      }, 1500)
                } else {
                    setErrorAlertState({
                        show: true,
                        message: response.error_message
                    });
                    setLoading(false);
                    console.log("ERROR: ", response.error_code);
                }
            } catch (e) {
                setErrorAlertState({
                    show: true,
                    message: e.response.data.error_message
                });
                setLoading(false);
            }
        } else {
            setValidated(true);
            setLoading(false);
        }
    }
      
    React.useEffect(() => {
        if(props.action === 'edit') {
            const source = CancelToken.source();
            getLeadData(source.token);
            return () => {
                source.cancel();
            };
        }
    }, []);

    return (
        !successAlert
        ? <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
            <Form.Row>
                <Form.Group as={Col} xs="3">
                    <Form.Label htmlFor="new-lead_channel"><b>Origine</b></Form.Label>
                    <Form.Control id="new-lead_channel" as="select" value={formParams.channel || ''} custom onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            channel: e.currentTarget.value
                        });
                        }} required>
                        <option label=" "></option>
                        {channels.map((type, i) => (
                            <option key={i} value={type.id}>{type.label}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs="3">
                    <Form.Label htmlFor="new-lead_crop_type"><b>Crop Type</b></Form.Label>
                    <Form.Control id="new-lead_crop_type" as="select" value={formParams.crop_type || ''} custom onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            crop_type: e.currentTarget.value
                        });
                    }} required >
                        <option label=" "></option>
                        {cropTypes.map((type, i) => (
                            <option key={i} value={type.id}>{type.label}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs="3">
                    <Form.Label htmlFor="new-lead_offer_type"><b>Offerta</b></Form.Label>
                    <Form.Control id="new-lead_offer_type" as="select" value={formParams.offer_type || ''} custom onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            offer_type: e.currentTarget.value
                        });
                        }} required >
                        <option label=" "></option>
                        {offerTypes.map((type, i) => (
                            <option key={i} value={type.id}>{type.label}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs="3">
                    <Form.Label htmlFor="new-lead_status"><b>Stato</b></Form.Label>
                    <Form.Control id="new-lead_status" as="select" value={formParams.status} custom onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            status: e.currentTarget.value
                        });
                        }} required >
                        <option label=" "></option>
                        {statuses.map((type, i) => (
                            <option key={i} value={type.id}>{type.label}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs="6" controlId="new-internal_id">
                    <Form.Label><b>ID Interno</b></Form.Label>
                    <Form.Control type="text" value={formParams.internal_id || ''} onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            internal_id: e.currentTarget.value
                        });
                    }} required />
                </Form.Group>
                <Form.Group as={Col} xs="6">
                    <Form.Label htmlFor="add-user"><b>Responsabile</b></Form.Label>
                    <AsyncTypeahead
                        {...addUserState}
                        id="add-user"
                        labelKey="email"
                        minLength={2}
                        onSearch={handleUserSearch}
                        onChange={(selected) => {
                            setAddUserState({ 
                              ...addUserState,
                              selected: selected
                            });
                            setFormParams({ 
                              ...formParams,
                              admin_display_name: selected[0] ? selected[0].display_name : '',
                              admin_email: selected[0] ? selected[0].email : '',
                              admin_user_id: selected[0] ? selected[0].id : '',
                              admin_username: selected[0] ? selected[0].username : ''
                            });
                        }}
                        renderMenuItemChildren={(option, props) => (
                            <div key={option.id}>
                                { option.email + " (" + option.display_name + ")" }
                            </div>
                        )}
                        options={addUserState.options}
                        inputProps={{ required: true }}
                        selected={addUserState.selected}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs="6" controlId="new-date_start">
                    <Form.Label><b>Date Start</b></Form.Label>
                    <div>
                        <DatePicker
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeInput
                            selected={formParams.date_start ? new Date(formParams.date_start) : null}
                            onChange={(date) => setFormParams({
                                ...formParams,
                                date_start: date
                            })}
                            locale="en"
                            className="form-control"
                            name="date_start" 
                            id="date_start" 
                            isClearable
                        />
                    </div>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs="6" controlId="new-first_name">
                    <Form.Label><b>Nome</b></Form.Label>
                    <Form.Control type="text" value={formParams.first_name || ''} onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            first_name: e.currentTarget.value
                        });
                    }} />
                </Form.Group>
                <Form.Group as={Col} xs="6" controlId="new-last_name">
                    <Form.Label><b>Cognome</b></Form.Label>
                    <Form.Control type="text" value={formParams.last_name || ''} onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            last_name: e.currentTarget.value
                        });
                    }} required />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs="6" controlId="new-email">
                    <Form.Label><b>Email</b></Form.Label>
                    <Form.Control type="text" value={formParams.email || ''} onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            email: e.currentTarget.value
                        });
                    }} required />
                </Form.Group>
                <Form.Group as={Col} xs="6" controlId="new-phone">
                    <Form.Label><b>Telefono</b></Form.Label>
                    <Form.Control type="text" value={formParams.phone || ''} onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            phone: e.currentTarget.value
                        });
                    }} required />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs="6" controlId="new-address_city">
                    <Form.Label><b>Città</b></Form.Label>
                    <Form.Control type="text" value={formParams.address_city || ''} onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            address_city: e.currentTarget.value
                        });
                    }} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="new-description">
                    <Form.Label><b>Descrizione</b></Form.Label>
                    <Form.Control type="text" value={formParams.description} onChange={(e) => {
                        setFormParams({
                            ...formParams,
                            description: e.currentTarget.value
                        });
                    }} required />
                </Form.Group>
            </Form.Row>
            <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
                ...errorAlertState,
                show: false
            })} dismissible>
                <Alert.Heading>Ops! C'è stato un errore!</Alert.Heading>
                <p>
                    { errorAlertState.message }
                </p>
            </Alert>
            <Form.Row className="mt-3">
                <Col>
                    <Button variant="primary" type="submit" disabled={loading}>
                        <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                        INVIA
                    </Button>
                </Col>
            </Form.Row>
        </Form>
        : <Alert variant="success">
            <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
        </Alert>
    );
}

export default ManageLead;