import React from 'react';
import Col from 'react-bootstrap/Col';
import { AuthContext } from '../../context/AuthProvider';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import API, { CancelToken } from '../../utils/AjaxUtils';
import { TableContext } from '../../context/TableProvider';
import { ModalContext } from '../../context/ModalProvider';

const ManageCredits = (props) => {

  const formParamsInitialState = {
    credits: props.credits.credits,
    unlimited: props.credits.unlimited
  };

  const errorAlertInitalState = {
    show: false,
    message: ''
  };

  const {state} = React.useContext(AuthContext);
  const [validated, setValidated] = React.useState(false);
  const [successAlert, setSuccessAlertState] = React.useState(false);
  const [formParams, setFormParams] = React.useState(formParamsInitialState);
  const [errorAlertState, setErrorAlertState] = React.useState(errorAlertInitalState);
  const {tableDispatch} = React.useContext(TableContext);
  const {modalDispatch} = React.useContext(ModalContext);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    setLoading(true);

    if (form.checkValidity()) {

      try {
          const token = state.user.token;
          const opts = {
              headers: {
                  'X-Elaisian-Authorization': token
              }
          };

          const response = await API.put('users/' + props.user_id + '/sms-credits', formParams, opts);
          
          if (response.data.status === 'ok') {
              setSuccessAlertState(true);

              setTimeout(function() {
                modalDispatch({
                    type: 'CLOSE'
                });
                tableDispatch({
                  type: 'DATA',
                  payload: {  
                    table: 'alerts-' + props.terrain_id,
                    data: []
                  }
                });
              }, 1500)
          } else {
              setErrorAlertState({
                show: true,
                message: response.error_message
              });
              setLoading(false);
              console.log("ERROR: ", response.error_code);
          }
      } catch (e) {
          setErrorAlertState({
            show: true,
            message: e.response.data.error_message
          });
          setLoading(false);
      }
    } else {
      setValidated(true);
      setLoading(false);
    }
  }

  return (
    !successAlert
    ? <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
        <Form.Row>
          <Form.Group as={Col} size="6" controlId="credits">
            <Form.Label><b>Credits</b></Form.Label>
            <Form.Control type="text" value={formParams.unlimited ? props.credits.credits : formParams.credits} readOnly={formParams.unlimited} onChange={(e) => {
              setFormParams({
                ...formParams,
                credits: e.currentTarget.value
              });
            }} required/>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Check
              custom
              type="switch"
              label="Unlimited"
              id="unlimited"
              checked={formParams.unlimited}
              onChange={(e) => {
                setFormParams({
                  ...formParams,
                  unlimited: e.target.checked
                });
              }}
            />
          </Form.Group>
        </Form.Row>
        <Alert variant="danger" show={errorAlertState.show} onClose={() => setErrorAlertState({
           ...errorAlertState,
           show: false
        })} dismissible>
          <Alert.Heading>Ops! C'è stato un errore!</Alert.Heading>
          <p>
            { errorAlertState.message }
          </p>
        </Alert>
        <Form.Row className="mt-3">
            <Col>
                <Button variant="primary" type="submit" disabled={loading}>
                  <span className={loading ? 'spinner-border spinner-border-sm mr-1' : 'spinner-border spinner-border-sm mr-1 sr-only'} role="status" aria-hidden="true"></span>
                  INVIA
                </Button>
            </Col>
        </Form.Row>
      </Form>
    : <Alert variant="success">
        <Alert.Heading className="p-5 text-center">Operazione conclusa con successo!</Alert.Heading>
      </Alert>
  );
}

export default ManageCredits;