import React from 'react';
import { useCookies } from 'react-cookie';
import { AuthContext } from './context/AuthProvider';
import Router from './config/Router';
import './App.css';

const App = () => {

  const {state, dispatch} = React.useContext(AuthContext);
  const [cookies] = useCookies(['user']);

  React.useEffect(() => {

    const user = cookies.user || null;

    if(user && user.token && user.roles) {
      if(!state.isAuthenticated) {
        dispatch({
          type: 'LOGIN',
          payload: {
            user
          }
        });
      }
    }
  }, [])

  return (
    <Router  />
  );
}

export default App;
