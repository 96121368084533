import React from 'react';
import moment from 'moment';
import { TableContext } from '../../context/TableProvider';
import  Filters  from '../filters/Filters';
import  Paginate  from '../paginate/Paginate';
import { FaChevronDown, FaChevronUp, FaCheck } from 'react-icons/fa';
import * as FontAwesome from "react-icons/fa";
import './Table.css';

const TableHead = (props) => {

  const {orderBy} = React.useContext(TableContext);

  const handleClick = (e) => {
    e.preventDefault();
    orderBy(props.table, e.target.id.split('-')[1]);
  }

  const Icon = (iconProps) => {

    const column = (iconProps.orders.length) ? iconProps.orders.find(value => value.name === iconProps.value) : null;

    return(
      column
      ? column.verse === 'desc' 
      ? <FaChevronUp />
      : <FaChevronDown />
      : <span></span>
    )
  }

  return (
    <thead>
      <tr scope="row">
        { props.actions.map((action) => ( 
          <th key={action.name} scope="col" className="text-center text-nowrap">
            {action.name}
          </th>
        )) }
        { props.columns.map((column) => ( 
          <th key={column.id} scope="col" className="text-capitalize text-nowrap">
            <span id={'column-' + column.id} onClick={handleClick}>{ column.name }</span> <Icon value={column.id} orders={props.orders} />
          </th> 
         )) 
        }
      </tr>
    </thead>
  )
};

const TableBody = (props) => {

  const CellValue = (cellProps) => {
    switch (cellProps.column.type) {
      case "boolean":
        if(cellProps.value) {
          return (
            <FaCheck />
          );
        } else {
          return (
            <React.Fragment>
              NO
            </React.Fragment>
          );
        }
      case "timestamp": 
        return (
          <React.Fragment>
            {moment(cellProps.value).format('YYYY-MM-DD')}
          </React.Fragment>
        );
      case "select":
        const index = cellProps.column.options.findIndex((obj => obj.id === cellProps.value || obj.value === cellProps.value));
        let classNamesSel;
        let labelTextSel;
        if (index !== -1) {
          classNamesSel = cellProps.column.classNames && cellProps.column.classNames[index] ? cellProps.column.classNames[index] : '';
          labelTextSel = cellProps.column.options[index].label || cellProps.column.options[index].name;
        } else {
          classNamesSel = '';
          labelTextSel = '[' + cellProps.value + ']';
        }
        return (
            <React.Fragment>
            <span className={classNamesSel}>
              {labelTextSel}
            </span>
            </React.Fragment>
        );
      case "sub-select":
        let label;
        cellProps.column.selects.forEach((select) => {
          let index = select.options.findIndex((obj => obj.id == cellProps.value || obj.value == cellProps.value));
          if(index !== -1) {
            label = select.options[index].label || select.options[index].name
          }
        });
        return (
            <React.Fragment>
            <span className={cellProps.column.classNames && cellProps.column.classNames[index] ? cellProps.column.classNames[index] : ''}>
              {label}
            </span>
            </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            { cellProps.value }
          </React.Fragment>
        );
    }
  }

  return (
    <tbody>
      { props.rows.map((row) => ( 
        <tr key={row.id} scope="row">
          { props.actions.map((action) => ( 
            <td key={action.name} className='text-nowrap text-center'>
              { action.iconType && 
                <div onClick={() => action.call(row)} className={action.className ? action.className : 'btn btn-dark'}>
                  { React.createElement(FontAwesome[action.iconType]) }
                </div>
              }
              {!action.iconType &&
                <button onClick={() => action.call(row)} className={action.className ? 'btn btn-' + action.className : 'btn btn-dark'}>{action.name}</button>
              }
            </td>
          )) }
          { props.columns.map((column) => ( 
            <td key={'row' + column.id} className={"text-nowrap"}>
              {
                column.more 
                ? <CellValue column={column} value={row[column.more][column.id]} />
                : <CellValue column={column} value={ column.showInTable ? row[column.showInTable] : row[column.id]} />
              }              
            </td>
          )) }
        </tr>
      )) }
    </tbody>
  )
};


const EmptyState = (props) => {
  return (
    !props.empty
    ? <tbody>
        <tr scope="row">
         <td className="text-nowrap text-center" colSpan="100%"><b>LOADING...</b></td>
        </tr>
      </tbody>
    : <tbody>
        <tr scope="row">
         <td className="text-nowrap text-center" colSpan="100%"><b>NO RESULTS</b></td>
        </tr>
      </tbody>
  )
};


const Table = (props) => {

  const {tableState} = React.useContext(TableContext);

  const TableContent = (contentProps) => {
    return (
      tableState[props.table].data.length
      ? <TableBody rows={tableState[props.table].data} columns={tableState[props.table].columns} actions={contentProps.actions} />
      : <EmptyState empty={tableState[props.table].empty} />
    );
  }

  if(tableState && tableState[props.table]) {
    return (
      <React.Fragment>
        <Filters table={props.table} />
        <div className="scroll-table">
          <table className="table">
            <TableHead table={props.table} columns={tableState[props.table].columns} orders={tableState[props.table].orders} actions={props.actions} />
            <TableContent actions={props.actions} />
          </table>
        </div>
        <Paginate table={props.table} />
      </React.Fragment>
    )
  } else {
    return(
      <React.Fragment></React.Fragment>
    );
  }
};

export default Table
