import React from 'react';
import {NavLink} from 'react-router-dom';
import { AuthContext } from '../../context/AuthProvider';
import { FaHome, FaUsers, FaMapPin } from 'react-icons/fa';
import { MdSettingsInputComponent, MdNotifications } from 'react-icons/md';
import { FaAddressBook, FaList } from 'react-icons/fa';
import { FiActivity } from 'react-icons/fi';
import { GiChemicalDrop } from 'react-icons/gi';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as Logo} from '../../imgs/elaisian-logo.svg';
import './SideBar.css';

const SideBar = () => {

	const {state, canManage} = React.useContext(AuthContext);

    return (
        <div className="bg-light" id="sidebar-wrapper">
	      <div className="sidebar-heading">
            <Logo alt="Elaisian" style={{ height: 50 }} />
	      </div>
	      <div className="list-group list-group-flush">
	      	<NavLink className="list-group-item list-group-item-action bg-light" activeClassName="active" title="Home" exact to="/">
	      		Home <FaHome className="pull-xs-right" /> 
	      	</NavLink>
	      	{
	      		canManage('users') &&
	      		<NavLink className="list-group-item list-group-item-action bg-light" activeClassName="active" title="Users" to="/users">
			    	Users <FaUsers className="pull-xs-right" />
			    </NavLink>
	      	}
	      	{
	      		canManage('terrains') &&
	      		<NavLink className="list-group-item list-group-item-action bg-light" activeClassName="active" title="Terrains" to="/terrains">
			    	Terrains <FaMapPin className="pull-xs-right" />
			   	</NavLink>
	      	}
	      	{
	      		canManage('devices') &&
	      		<NavLink className="list-group-item list-group-item-action bg-light" activeClassName="active" title="Devices" to="/devices">
			    	Devices <MdSettingsInputComponent className="pull-xs-right" />
			   	</NavLink>
	      	}
	      	{
	      		canManage('advices') &&
	      		<NavLink className="list-group-item list-group-item-action bg-light" activeClassName="active" title="Notifications" to="/notifications">
			    	Notifications <MdNotifications className="pull-xs-right" />
			   	</NavLink>
	      	}
	      	{
	      		canManage('activities') &&
	      		<Accordion>
		      		<Accordion.Toggle as={NavLink} className="list-group-item list-group-item-action bg-light" activeClassName="" title="Activities" eventKey="0" to="#">
		      			Activities <FiActivity className="pull-xs-right" />
		      		</Accordion.Toggle>
		      		<Accordion.Collapse eventKey="0">
		      			<React.Fragment>
					        <NavLink className="list-group-item list-group-item-action bg-light pl-5" activeClassName="active" to="/activities">
						    	<MdSubdirectoryArrowRight /> List <FaList className="pull-xs-right" />
						   	</NavLink>
						   	<NavLink className="list-group-item list-group-item-action bg-light pl-5" activeClassName="active" to="/products">
						    	<MdSubdirectoryArrowRight /> Products <GiChemicalDrop className="pull-xs-right" />
						   	</NavLink>
					   	</React.Fragment>
				    </Accordion.Collapse>
	      		</Accordion>
	      		
	      	}
	      	{
	      		canManage('sales') &&
	      		<NavLink className="list-group-item list-group-item-action bg-light" activeClassName="active" title="Leads" to="/leads">
			    	Leads <FaAddressBook className="pull-xs-right" />
			   	</NavLink>
	      	}
	      </div>
	    </div>
    )
}

export default SideBar;