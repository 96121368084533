import React from 'react';
import { AuthContext } from "../../context/AuthProvider";
import { FiMenu } from 'react-icons/fi';
import { FaUser } from 'react-icons/fa';
import {MdSettings} from 'react-icons/md';
import {NavLink} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { LayoutContext } from '../../context/LayoutProvider';

const AuthStatus = () => {

  const { state, dispatch } = React.useContext(AuthContext);
  const [cookies, removeCookie] = useCookies(['user']);

  const logOut = () => {
      removeCookie('user', {});
      dispatch({
        type: "LOGOUT"
      })
  };

  return (
        state.isAuthenticated
        ? <Dropdown>
            <Dropdown.Toggle variant="secondary" className="bmd-btn-fab bmd-btn-fab-sm" id="dropdown-user">
              <FaUser />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header>{state.user.name}</Dropdown.Header>
              <Dropdown.Item onClick={() =>
                logOut()
              }>Sign out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> 
       : <NavLink className="nav-link" exact to="/login"><Button variant="dark"><b>LOGIN</b></Button></NavLink>
  )
}
  
const Header = () => {

  const { layoutDispatch } = React.useContext(LayoutContext);
  const { state } = React.useContext(AuthContext);

  return (
    <nav className="navbar navbar-expand-lg navbar-light" style={{boxShadow: 'none'}}>
      <Button variant="link" size="lg" onClick={() => {
         layoutDispatch({
              type: 'TOGGLE_SIDEBAR'
          })
      }}>
        <FiMenu />
      </Button>
      <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
        {
          state.isAuthenticated
          ? <li className="nav-item mr-3">
              <Dropdown>
                <Dropdown.Toggle variant="info" className="bmd-btn-fab bmd-btn-fab-sm" id="dropdown-system-tools">
                  <MdSettings />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <NavLink className="dropdown-item" title="System Tools" to="/system-tools">
                    System Tools
                  </NavLink>
                </Dropdown.Menu>
              </Dropdown> 
            </li>
          : <React.Fragment></React.Fragment>
        }
        <li className="nav-item mr-3">
          <AuthStatus />
        </li>
      </ul>
    </nav>
  )
}

export default Header;