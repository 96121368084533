import React from 'react';
import { TableContext } from '../../context/TableProvider';
//import Form from 'react-bootstrap/Form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { AuthContext } from '../../context/AuthProvider';
import API, { CancelToken } from '../../utils/AjaxUtils';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import './Filters.css';

const Operator = (props) => {

  const column = props.columns.find(value => value.id === props.value);

  const handleTimeStampChange = (e) => {
    if(e.target.value === 'between' || e.target.value === 'not_between' ) {
      props.setEndDate({
        [e.target.id.split('-')[1]]: new Date() 
      });
    }
  }

  /*<option value="between">between</option>
  <option value="not_between">not_between</option>*/

  switch (column.type) {
    case "string":
      return(
        <select name={'operator-' + props.value} id={'operator-' + props.value} className="form-control filter" defaultValue={'contains'}>
          <option value="equals">equals</option>
          <option value="not_equals">not equals</option>
          <option value="contains">contains</option>
          <option value="not_contains">not contains</option>
          <option value="starts_with">starts with</option>
          <option value="not_starts_with">not starts with</option>
          <option value="ends_with">ends with</option>
          <option value="not_ends_with">not ends with</option>
        </select>
      );
    case "boolean":
      return(
        <select name={'operator-' + props.value} id={'operator-' + props.value} className="form-control filter" defaultValue={'equals'}>
          <option value="equals">equals</option>
          <option value="not_equals">not equals</option>
        </select>
      );
    case "timestamp":
      return(
        <select name={'operator-' + props.value} id={'operator-' + props.value} className="form-control filter" defaultValue={'after'} onChange={handleTimeStampChange}>
          <option value="equals">equals</option>
          <option value="not_equals">not equals</option>
          <option value="before">before</option>
          <option value="not_before">not before</option>
          <option value="after">after</option>
        </select>
      );
    default:
      return(
        <input type="hidden" name={'operator-' + props.value} id={'operator-' + props.value} value="equals" />
      );
  }
}

const Input = (props) => {

  const column = props.column;
  const {state} = React.useContext(AuthContext);

  const handleDatePickerChange = (date) => {
    // TODO FINIRE
    console.log(date);
  }

  const handleChange = (e) => {
    e.preventDefault();
  }

  const handleAutoCompleteSearch = async (query) => {

      props.setAutoCompleteState({ 
          ...props.autoCompleteState,
          [column.id]: {
            ...props.autoCompleteState[column.id],
            isLoading: true 
          }
      });

      const autoCompleteResponse = await API.get(column.url, {
          headers: {
            'X-Elaisian-Authorization': state.user.token
          },
          params: {
            filter: column.paramName + ',contains,' + query,
            search_type: 'ac'
          }
      });

      if (autoCompleteResponse.data.status === 'ok') {
        props.setAutoCompleteState({ 
          ...props.autoCompleteState,
          [column.id]: {
            ...props.autoCompleteState[column.id],
            isLoading: false,
            options: autoCompleteResponse.data.result[column.result]
          }
        });
      } else {
        props.setAutoCompleteState({ 
          ...props.autoCompleteState,
          [column.id]: {
            ...props.autoCompleteState[column.id],
            isLoading: false 
          }
      });
        console.log("ERROR: ", autoCompleteResponse.error_code);
      }
  }

  switch (column.type) {
    case "string":
        return(
          <React.Fragment>
            <label htmlFor={column.id} className="justify-content-start mb-1 col-form-label"><b>{ column.name }</b></label>
            <input className="form-control mr-1 mt-2" type="text" onChange={handleChange} name={column.id} id={column.id} />
          </React.Fragment>
        );
    case "select":
        return(
          <React.Fragment>
            <label htmlFor={column.id} className="justify-content-start mb-1 col-form-label"><b>{ column.name }</b></label>
            <select name={column.id} id={column.id} className="form-control mr-1" onChange={(e) => props.subSelects ? props.setSubSelects({...props.subSelectsInitialState, [e.target.value]: true}) : console.log(e)}>
              <option label=""></option>
              {column.options.map((type, i) => (
                <option key={i} value={ type.value || type.id }>{ type.label || type.name }</option>
              ))}
            </select>
          </React.Fragment>
        );
    case "sub-select":
        return(
          <React.Fragment>
            {column.selects.map((select, i) => (
              props.subSelects[select.id] ? 
              <div key={select.id}>
                <label htmlFor={column.id} className="justify-content-start mb-1 col-form-label"><b>{ column.name }</b></label>
                <select name={column.id} id={column.id} className="form-control mr-1">
                  <option label=""></option>
                  {select.options.map((type, p) => (
                    <option key={type.id} value={ type.value || type.id }>{ type.label || type.name }</option>
                  ))}
                </select>
              </div> : <React.Fragment key={select.id}></React.Fragment>
            ))}
          </React.Fragment>
        );
    case "boolean":
      return(
        <React.Fragment>
            <label htmlFor={column.id} className="justify-content-start mb-1 col-form-label"><b>{ column.name }</b></label>
            <select name={column.id} id={column.id} className="form-control mr-1">
              <option label=""></option>
              <option value="true">TRUE</option>
              <option value="false">FALSE</option>
            </select>
        </React.Fragment>
      );
      /*return(
        <input className="form-control mr-1 mt-2" type="text" onChange={handleChange} name={column.id} id={column.id} placeholder={column.name } />
      );*/
    case "autocomplete":
      return(
        <React.Fragment>
            <label htmlFor={column.id} className="justify-content-start mb-1 col-form-label"><b>{ column.name }</b></label>
            <AsyncTypeahead
              {...props.autoCompleteState[column.id]}
              id={column.id}
              labelKey={column.labelKey}
              minLength={3}
              onSearch={handleAutoCompleteSearch}
              onChange={(selected) => {
                props.setAutoCompleteState({ 
                  ...props.autoCompleteState,
                  [column.id]: {
                    ...props.autoCompleteState[column.id],
                    selected: selected
                  }
                });
              }}
              renderMenuItemChildren={(option, props) => (
                <div key={option.id}>
                  { option[column.displayName] }
                </div>
              )}
              className='mt-3'
              placeholder={column.label}
              options={props.autoCompleteState[column.id].options}
              selected={props.autoCompleteState[column.id].selected}
            />
            {props.autoCompleteState[column.id].selected.map((selected, i) => (
              <input key={selected.id} name={column.id} value={selected.id} type="checkbox" onChange={handleChange} style={{display: 'none'}} checked/>
            ))}
        </React.Fragment>
      );
    case "timestamp":

      const startDate = (props.startDate && props.startDate[column.id]) ? props.startDate[column.id] : null;
      const endDate = (props.endDate && props.endDate[column.id]) ? props.endDate[column.id] : null;

      return(
        /*!endDate
        ? */
        <React.Fragment>
          <label htmlFor={column.id} className="justify-content-start mb-1 col-form-label"><b>{ column.name }</b></label>
          <DatePicker
            dateFormat="yyyy-MM-dd HH.mm.ss"
            selected={startDate}
            onChange={date => props.setStartDate({
              ...props.startDate,
              [column.id]: date
            })}
            locale="en"
            timeFormat="HH:mm"
            className="form-control mr-1 mt-2"
            name={column.id} 
            id={column.id} 
          />
        </React.Fragment>
        /*: <div className="display-inline">
            <DatePicker
            dateFormat="yyyy-MM-dd HH.mm.ss"
            selected={startDate}
            onChange={date => props.setStartDate({
              ...props.startDate,
              [props.value]: date
            })}
            locale="en"
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            timeFormat="HH:mm"
            className="form-control mr-1"
            name={'start-'+ props.value} 
            id={'start-'+ props.value} 
            placeholderText={ column.name + ' Start' }
          />

          <DatePicker
            dateFormat="yyyy-MM-dd HH.mm.ss"
            selected={endDate}
            onChange={date => props.setEndDate({
              ...props.endDate,
              [props.value]: date
            })}
            locale="en"
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            timeFormat="HH:mm"
            className="form-control mr-1"
            name={'end-'+ props.value} 
            id={'end-'+ props.value} 
            placeholderText={ column.name + ' End'}
          />
          <input type="hidden" id={props.value} name={props.value} />
        </div>*/
      );
   }
}

const Form = (props) => {

  const findSubSelect = props.columns.find((c) => c.type === 'sub-select');
  const findAutocomplete = props.columns.filter(item => item.type.indexOf('autocomplete') !== -1);
  const subSelectsInitialState = findSubSelect ? Object.fromEntries(findSubSelect.selects.map((e) => { return [e.id, false]})) : [];
  const autocompleteInitialState = Object.fromEntries(findAutocomplete.map((e) => { return [e.id, {
    isLoading: false,
    selected: [],
    options: []
  }]}));

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const {filterBy, reset} = React.useContext(TableContext);
  const [subSelects, setSubSelects] = React.useState(subSelectsInitialState);
  const [autoCompleteState, setAutoCompleteState] = React.useState(autocompleteInitialState);

  const deleteFilter = (filter) => {
    console.log(filter);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formElements = e.target.elements;

    const filters = props.columns.map((column) => {
      return {
        name: column.id,
        operator: formElements['operator-' + column.id].value,
        value: formElements[column.id] ? formElements[column.id].value : ''
      }
    });

    filterBy(props.table, filters.filter(function(element) {
      if (!element.value) {
        return false;
      }
      return true;
    }));
  }

  const handleResetClick = (e) => {
    e.preventDefault();
    setStartDate(null);
    setEndDate(null);
    setAutoCompleteState(autocompleteInitialState);
    setSubSelects(subSelectsInitialState);
    reset(props.table);
  }

  return (
    <form onSubmit={handleSubmit} className="form-inline mb-0">
      <div className="form-row">
          { props.columns.map((column) => ( 
            <div key={'input-' + column.id} className="col-auto m-1">
              <Input column={column} columns={props.columns} autoCompleteState={autoCompleteState} setAutoCompleteState={setAutoCompleteState} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} subSelects={subSelects} subSelectsInitialState={subSelectsInitialState} setSubSelects={setSubSelects} />
              <Operator value={column.id} columns={props.columns} autoCompleteState={autoCompleteState} startDate={startDate} setEndDate={setEndDate} />
            </div> 
           )) 
          }
          <div className="w-100"></div>
          <div className="col m-1">
            <button type="submit" className="btn btn-primary mr-1">INVIA</button>
            <button type="reset" onClick={handleResetClick} className="btn btn-secondary">RESET</button>
          </div>
      </div>
    </form>
  )
};

const Filters = (props) => {

  const {tableState} = React.useContext(TableContext);

  return (
    !tableState[props.table].hideFilters
    ? <div className="mt-3">
        <Form table={props.table} columns={tableState[props.table].columns} />
      </div>
    : <React.Fragment></React.Fragment>
  )
};

export default Filters
