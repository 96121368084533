import React from 'react';
import moment from 'moment';
import { AuthContext } from '../../context/AuthProvider';
import API, { CancelToken } from '../../utils/AjaxUtils';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { FaCheck } from 'react-icons/fa';
import { TableContext } from '../../context/TableProvider';

const DetailsContent = (props) => {

    const CellValue = (cellProps) => {
        switch (cellProps.column.type) {
          case "boolean":
            if(cellProps.value) {
              return (
                <FaCheck />
              );
            } else {
              return (
                <React.Fragment>
                  NO
                </React.Fragment>
              );
            }
          case "timestamp":
            return (
              <React.Fragment>
                {moment(cellProps.value).format('YYYY-MM-DD')}
              </React.Fragment>
            );
          case "select":
            const index = cellProps.column.options.findIndex((obj => obj.id == cellProps.value || obj.value == cellProps.value));
            return (
              <React.Fragment>
                <span className={cellProps.column.classNames && cellProps.column.classNames[index] ? cellProps.column.classNames[index] : ''}>
                  {cellProps.column.options[index].label || cellProps.column.options[index].name}
                </span>
              </React.Fragment>
            );
          case "sub-select":
            let label = [];
            cellProps.column.selects.forEach((select) => {
              select.options.forEach((option) => {
                if(option.id == cellProps.value || option.value == cellProps.value || cellProps.value.includes(option.id)) {
                  label.push(option.label || option.name);
                }
              });
            });
            return (
              <React.Fragment>
                <span className={cellProps.column.classNames && cellProps.column.classNames[index] ? cellProps.column.classNames[index] : ''}>
                  {label.join(', ')}
                </span>
              </React.Fragment>
            );
          default:
            return (
              <React.Fragment>
                { cellProps.value }
              </React.Fragment>
            );
        }
    }

    return (
        Object.keys(props.data).length
        ? <div className="row">
            <div className="col">
                <table className="table table-bordered">
                    <tbody>
                        {props.columns.map((column) => {
                            return (
                                <tr key={'modal-' + column.id}>
                                    <td scope="row"><b>{column.name}</b></td>
                                    <td>
                                        <CellValue column={column} value={column.showInTable ? props.data[column.showInTable] : props.data[column.id]} />
                                    </td>
                                </tr>
                            )}
                        )}   
                    </tbody>
                 </table>   
            </div>
        </div>
        : <div className="row">
              <div className="col text-center">LOADING...</div>
          </div>
    )
}


const Details = (props) => {
    const {state} = React.useContext(AuthContext);
    const {tableState, tableDispatch} = React.useContext(TableContext);
    const [defaultActiveKey, setDefaultActiveKey] = React.useState(0);
    const [element, setElement] = React.useState(null);
    const table = props.table || props.what;
    const columns = (props.customColumns) ? tableState[table].columns.concat(props.customColumns) : tableState[table].columns;

    const getDetailsData = async (cancelToken) => {
        try {
            const opts = {
              headers: {
                 'X-Elaisian-Authorization': state.user.token
              },
              cancelToken: cancelToken
            };
            const response = await API.get(props.what + '/' + props.id, opts);
            if (response.data.status === 'ok') {
                setElement(response.data.result);
                tableDispatch({
                  type: 'DATA',
                  payload: {  
                    table: table + 'Details-' + props.id,
                    data: response.data.result
                  }
                });
                //setDetails(response.data.result);
            } else {
                console.log("ERROR: ", response.error_code);
            }
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {

        const source = CancelToken.source();

        if(!tableState[table + 'Details-' + props.id] && !element) {
            tableDispatch({
              type: 'INIT',
              payload: {  
                table: table + 'Details-' + props.id,
                columns: [],
                data: [],
                filters: [],
                orders: []
              }
            });
        } else if(tableState[table + 'Details-' + props.id] && !element) {
          setElement(tableState[table + 'Details-' + props.id].data);
        }

        if(tableState && tableState[table + 'Details-' + props.id] && !Object.keys(tableState[table + 'Details-' + props.id].data).length) {
          getDetailsData(source.token);
        }

        return () => {
            source.cancel();
        };
    }, [tableState[table + 'Details-' + props.id]]);

    return (
        tableState[table + 'Details-' + props.id] && Object.keys(tableState[table + 'Details-' + props.id].data).length && element
        ? <div className="container">
            <div className="row">
                <div className="col">
                   <DetailsContent columns={columns} data={tableState[table + 'Details-' + props.id].data} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Accordion defaultActiveKey={defaultActiveKey}>
                        {props.components.map((component, i) => {
                            return (
                                component && component.load
                                ? <Card key={'details-compoent-' + i}>
                                    <Accordion.Toggle as={Card.Header} eventKey={i} onClick={() => { setDefaultActiveKey(i); }} style={{cursor: 'pointer'}}>
                                      { component.name }
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={i}>
                                      <Card.Body>
                                        { React.Children.map(component.load, child => React.cloneElement(child, { element: element })) }
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                : <React.Fragment key={'details-compoent-' + i} />
                            )}
                        )} 
                    </Accordion>
                </div>
            </div>
          </div>
        : <div className="text-center m-3"><b>LOADING...</b></div>
    )
}

export default Details;